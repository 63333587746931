import { WorkSheet, utils, writeFile } from 'xlsx'

export function exportExcel({ filename, data, sheetName, format = (ws) => ws }: { filename: string; data: any[]; sheetName: string; format?: (i: WorkSheet) => WorkSheet }) {
  const ws = utils.json_to_sheet(data)
  const formatted = format(ws)
  const wb = utils.book_new()
  utils.book_append_sheet(wb, formatted, sheetName)
  writeFile(wb, filename)
}

export function exportMultiExcel({
  filename,
  allData,
}: {
  filename: string
  allData: {
    format?: (i: WorkSheet) => WorkSheet
    sheetName: string
    data: any[]
  }[]
}) {
  const wb = utils.book_new()
  for (const { data, sheetName, format = (ws) => ws } of allData) {
    const ws = utils.json_to_sheet(data, { skipHeader: true })
    const formatted = format?.(ws)
    utils.book_append_sheet(wb, formatted, sheetName)
  }
  writeFile(wb, filename)
}
