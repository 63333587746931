import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { defaultRetry } from '../utils/utils'

type DealLogProps = {
  dealId?: string
  enabled?: boolean
}

type DealLogListProps = {
  dealIds?: string[]
  enabled?: boolean
}

export type DealLogHeaders = {
  Company: string
  'NEP Lead': string
  'Close Date': string
  'Round Name': string
  'VC Source': string
  'Other Source': string
  Status: string
  'Rami Tiering': string
  'Mgmt. meeting': string
  'Expert Calls': string
  'Term Sheet Submitted': string
  'IC Discussions': string
  'Deck Created': string
  PlaybookID: string
  PlaybookDealID: string
  index: number
}

export function useDealLogList(props?: DealLogListProps): [Record<string, DealLogHeaders>, boolean, Error | undefined] {
  const { dealIds, enabled = true } = props || {}
  const { data, isLoading, error } = useQuery({
    queryKey: ['dealLog', [...(dealIds || [])].sort().join(',')],
    enabled: enabled && (!dealIds || dealIds.length > 0),
    queryFn: queryDealLogList(dealIds),
    retry: defaultRetry,
  })

  return [
    data?.dealLog.reduce((p, c) => {
      p[c.PlaybookDealID] = c
      return p
    }, {} as any) || {},
    isLoading,
    error as Error | undefined,
  ]
}

export function useDealLog({ dealId, enabled = true }: DealLogProps): [DealLogHeaders[], boolean, Error | undefined] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['companyProfile/dealLog', dealId],
    enabled: !!dealId && enabled,
    queryFn: queryDealLog(dealId as string),
    retry: defaultRetry,
  })

  return [data?.dealLog || ([] as any), isLoading, error as Error | undefined]
}

export function queryDealLog(dealId: string): () => Promise<{ dealLog: DealLogHeaders[] }> {
  return async () =>
    restAPIRequest<{ dealLog: DealLogHeaders[] }>({
      path: `companyProfile/${dealId}/dealLog`,
      method: 'get',
    })
}

export function queryDealLogList(dealIds?: string[]): () => Promise<{ dealLog: DealLogHeaders[] }> {
  return async () =>
    restAPIRequest<{ dealLog: DealLogHeaders[] }>({
      path: `dealLog`,
      method: 'post',
      body: { dealIds },
    })
}
