import { Button, Flex, ScrollView, SelectField } from '@aws-amplify/ui-react'
import { CellChange, Column, DefaultCellTypes, NumberCell, ReactGrid, Row } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import GlassCard from '../figma-components/GlassCard'
import { FinancialDataV2 } from '../models'
import { exportMultiExcel } from '../services/excel'
import '../styles/dashboard.css'
import '../styles/grid.scss'
import '../styles/table.css'
import CompanyHeader from './CompanyHeader'
import { CurrencyCell, CurrencyCellTemplate } from './CurrencyCell'
import ExportButton from './ExportButton'
import { HeaderCell, HeaderCellTemplate } from './HeaderCell'
import { HorizontalChevronCell } from './HorizontalChevronCell'
import { MultipleCellTemplate } from './MultipleCell'
import { PercentCell, PercentCellTemplate } from './PercentCell'

type DateTypes = 'yearly' | 'quarterly' | 'monthly' | 'ntm' | 'ltm'

const nonEditable = (cell: RowCells): RowCells => ({
  ...cell,
  nonEditable: true,
})

type InternalCol = Column & { headerName?: string; type: DateTypes | 'header'; year?: number; quarter?: number; month?: number }

const getColumns = (): InternalCol[] => {
  const currentYear = new Date().getFullYear()
  const columns: InternalCol[] = [{ columnId: 'headers', width: 250, headerName: 'Period', type: 'header' }]

  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    columns.push({ columnId: `${i}-Q1-0`, headerName: `Jan ${i}`, type: 'monthly', year: i, month: 0, quarter: 1 })
    columns.push({ columnId: `${i}-Q1-1`, headerName: `Feb ${i}`, type: 'monthly', year: i, month: 1, quarter: 1 })
    columns.push({ columnId: `${i}-Q1-2`, headerName: `Mar ${i}`, type: 'monthly', year: i, month: 2, quarter: 1 })
    columns.push({ columnId: `${i}-Q1`, headerName: `Q1 ${i}`, type: 'quarterly', year: i, quarter: 1 })

    columns.push({ columnId: `${i}-Q2-3`, headerName: `Apr ${i}`, type: 'monthly', year: i, month: 3, quarter: 2 })
    columns.push({ columnId: `${i}-Q2-4`, headerName: `May ${i}`, type: 'monthly', year: i, month: 4, quarter: 2 })
    columns.push({ columnId: `${i}-Q2-5`, headerName: `Jun ${i}`, type: 'monthly', year: i, month: 5, quarter: 2 })
    columns.push({ columnId: `${i}-Q2`, headerName: `Q2 ${i}`, type: 'quarterly', year: i, quarter: 2 })

    columns.push({ columnId: `${i}-Q3-6`, headerName: `Jul ${i}`, type: 'monthly', year: i, month: 6, quarter: 3 })
    columns.push({ columnId: `${i}-Q3-7`, headerName: `Aug ${i}`, type: 'monthly', year: i, month: 7, quarter: 3 })
    columns.push({ columnId: `${i}-Q3-8`, headerName: `Sep ${i}`, type: 'monthly', year: i, month: 8, quarter: 3 })
    columns.push({ columnId: `${i}-Q3`, headerName: `Q3 ${i}`, type: 'quarterly', year: i, quarter: 3 })

    columns.push({ columnId: `${i}-Q4-9`, headerName: `Oct ${i}`, type: 'monthly', year: i, month: 9, quarter: 4 })
    columns.push({ columnId: `${i}-Q4-10`, headerName: `Nov ${i}`, type: 'monthly', year: i, month: 10, quarter: 4 })
    columns.push({ columnId: `${i}-Q4-11`, headerName: `Dec ${i}`, type: 'monthly', year: i, month: 11, quarter: 4 })
    columns.push({ columnId: `${i}-Q4`, headerName: `Q4 ${i}`, type: 'quarterly', year: i, quarter: 4 })

    columns.push({ columnId: `${i}`, headerName: `CY ${i}`, type: 'yearly', year: i })
  }

  columns.push({ columnId: 'ltm', headerName: 'LTM', type: 'ltm' })
  columns.push({ columnId: 'ntm', headerName: 'NTM', type: 'ntm' })

  return columns
}

function headerRow(cols): Row<HeaderCell> {
  return {
    rowId: 'header',
    cells: cols.map((col) => {
      const { columnId, headerName } = col
      const text = headerName
      const isYear = columnId.length === 4
      return { type: 'header', columnId: columnId, text, className: isYear ? 'year' : '' }
    }),
    height: 38,
  }
}

const keyToHeader = {
  startingARR: { displayName: 'Starting ARR', type: 'currencyCell', sheet: 'metricsOutput' },
  newSalesARR: { displayName: 'New Sales ARR', type: 'currencyCell', sheet: 'metricsOutput' },
  expansionARR: { displayName: 'Expansion ARR', type: 'currencyCell', sheet: 'metricsOutput' },
  resurrectedARR: { displayName: 'Resurrected ARR', type: 'currencyCell', sheet: 'metricsOutput' },
  contractionARR: { displayName: 'Contraction ARR', type: 'currencyCell', sheet: 'metricsOutput' },
  churnedARR: { displayName: 'Churned ARR', type: 'currencyCell', sheet: 'metricsOutput' },
  totalARR: { displayName: 'Total ARR ($)', type: 'currencyCell', sheet: 'metricsOutput' },
  netNewARR: { displayName: 'Net New ARR ($)', type: 'currencyCell', sheet: 'metricsOutput' },
  netNewARRGrowthPercent: { displayName: 'Net New ARR Growth %', type: 'percentCell', sheet: 'metricsOutput' },
  netNewARRYoYGrowthPercent: { displayName: 'Net New ARR - YoY Growth', type: 'percentCell', sheet: 'metricsOutput' },
  netNewARRL3MCMGRPercent: { displayName: 'Net New ARR - L3M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  netNewARRL12MCMGRPercent: { displayName: 'Net New ARR - L12M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  netNewARRL6MCMGRPercent: { displayName: 'Net New ARR - L6M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  magicNumber: { displayName: 'Magic Number', type: 'number', sheet: 'metricsOutput' },
  netRetentionPercent: { displayName: 'Net Retention %', type: 'percentCell', sheet: 'metricsOutput' },
  grossRetentionPercent: { displayName: 'Gross Retention %', type: 'percentCell', sheet: 'metricsOutput' },
  cacPayback: { displayName: 'CAC Payback', type: 'number', sheet: 'metricsOutput' },
  burnMultiple: { displayName: 'Burn Multiple', type: 'multipleCell', sheet: 'metricsOutput' },
  customers: { displayName: 'Customers', type: 'number', sheet: 'metricsOutput' },
  customersGrowth: { displayName: 'Customers - Growth', type: 'percentCell', sheet: 'metricsOutput' },
  customersYoYGrowth: { displayName: 'Customers - YoY Growth', type: 'percentCell', sheet: 'metricsOutput' },
  customersL3MCMGR: { displayName: 'Customers - L3M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  customersL6MCMGR: { displayName: 'Customers - L6M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  customersL12MCMGR: { displayName: 'Customers - L12M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  newCustomers: { displayName: 'New Customers', type: 'number', sheet: 'metricsOutput' },
  churnedCustomers: { displayName: 'Churned Customers', type: 'number', sheet: 'metricsOutput' },
  salesEfficiencyMultiple: { displayName: 'Sales Efficiency', type: 'multipleCell', sheet: 'metricsOutput' },
  quickRatio: { displayName: 'Quick Ratio', type: 'percentCell', sheet: 'metricsOutput' },
  totalHeadcount: { displayName: 'Total Headcount', type: 'number', sheet: 'metricsOutput' },
  totalHeadcountGrowth: { displayName: 'Total Headcount - Growth', type: 'percentCell', sheet: 'metricsOutput' },
  totalHeadcountYoYGrowth: { displayName: 'Total Headcount - YoY Growth', type: 'percentCell', sheet: 'metricsOutput' },
  totalHeadcountL3MCMGR: { displayName: 'Total Headcount - L3M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  totalHeadcountL6MCMGR: { displayName: 'Total Headcount - L6M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  totalHeadcountL12MCMGR: { displayName: 'Total Headcount - L12M CMGR', type: 'percentCell', sheet: 'metricsOutput' },
  rndHeadcount: { displayName: 'R&D Headcount', type: 'number', sheet: 'metricsOutput' },
  cogsHeadcount: { displayName: 'COGS Headcount', type: 'number', sheet: 'metricsOutput' },
  snmHeadcount: { displayName: 'S&M Headcount', type: 'number', sheet: 'metricsOutput' },
  gnaHeadcount: { displayName: 'G&A Headcount', type: 'number', sheet: 'metricsOutput' },
  arrPerEmployee: { displayName: 'ARR $ / Employee', type: 'currencyCell', sheet: 'metricsOutput' },
  implied5yrLTVPerCAC: { displayName: 'Implied 5yr LTV/CAC', type: 'multipleCell', sheet: 'metricsOutput' },

  gnaAsPercentOfRev: { displayName: 'G&A as % of Rev', type: 'percentCell', sheet: 'metricsOutput' },
  snmAsPercentOfRev: { displayName: 'S&M as % of Rev', type: 'percentCell', sheet: 'metricsOutput' },
  rndAsPercentOfRev: { displayName: 'R&D as % of Rev', type: 'percentCell', sheet: 'metricsOutput' },
  arrGrowthPercent: { displayName: 'ARR Growth (%)', type: 'percentCell', sheet: 'metricsOutput' },

  totalFCF: { displayName: 'Total FCF ($)', type: 'currencyCell', sheet: 'metricsOutput' },
  fcfMarginPercent: { displayName: 'FCF Margin (%)', type: 'percentCell', sheet: 'metricsOutput' },
  annualizedOpexPerEmployee: { displayName: 'Opex $ / Employee', type: 'currencyCell', sheet: 'metricsOutput' },
  operatingIncomePercent: { displayName: 'Operating Income %', type: 'percentCell', sheet: 'metricsOutput' },
  ruleOf40LTMFCF: { displayName: 'Rule of 40 (LTM FCF)', type: 'percentCell', sheet: 'metricsOutput' },
  ruleOf40LTMEbitda: { displayName: 'Rule of 40 (LTM EBITDA)', type: 'percentCell', sheet: 'metricsOutput' },

  totalRevenue: { displayName: 'Total Revenue ($)', type: 'currencyCell', sheet: 'pnlOutput' },
  cogs: { displayName: 'COGS ($)', type: 'currencyCell', sheet: 'pnlOutput' },
  totalGrossProfit: { displayName: 'Total Gross Profit ($)', type: 'currencyCell', sheet: 'pnlOutput' },
  grossMarginPercent: { displayName: 'Gross Margin (%)', type: 'percentCell', sheet: 'pnlOutput' },
  snmExpenses: { displayName: 'S&M Expenses', type: 'currencyCell', sheet: 'pnlOutput' },
  rndExpenses: { displayName: 'R&D Expenses', type: 'currencyCell', sheet: 'pnlOutput' },
  gnaExpenses: { displayName: 'G&A Expenses', type: 'currencyCell', sheet: 'pnlOutput' },
  operatingExpenses: { displayName: 'Operating Expenses', type: 'currencyCell', sheet: 'pnlOutput' },
  totalEBITDA: { displayName: 'Total EBITDA ($)', type: 'currencyCell', sheet: 'pnlOutput' },
  eBITDAMarginPercent: { displayName: 'EBITDA Margin (%)', type: 'percentCell', sheet: 'pnlOutput' },
  totalRevGrowthPercent: { displayName: 'Total Rev Growth (%)', type: 'percentCell', sheet: 'pnlOutput' },
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function getDateString({ type, year, month, quarter }: FinancialDataV2): string {
  let formattedDate = ''

  if (type === 'monthly' && typeof month === 'number') {
    formattedDate = `${monthNames[month]} ${year}`
  } else if (type === 'quarterly' && typeof quarter === 'number') {
    formattedDate = `${year} Q${quarter}`
  } else if (type === 'yearly') {
    formattedDate = `${year}`
  } else if (type === 'ltm') {
    formattedDate = 'LTM'
  } else if (type === 'ntm') {
    formattedDate = 'NTM'
  }

  formattedDate === '' && console.warn('could not create date', { type, year, month, quarter })
  return formattedDate
}
type FinancialKeys = keyof typeof keyToHeader

type MetricsOutputKeys = Record<string, FinancialKeys>
type PNLOutputKeys = Record<string, FinancialKeys>

type FinOutputs = Record<string, string | number>

type RowCells = NumberCell | HorizontalChevronCell | HeaderCell | CurrencyCell | DefaultCellTypes | PercentCell

function getRowFor(data: FinancialDataV2[], colIds: string[], key: string): Row<RowCells> {
  const details = keyToHeader[key]
  const headerName = details.displayName || 'N/A'
  return {
    rowId: key,
    cells: [
      ...colIds.map<RowCells>((colId, idx) => {
        if (idx === 0) return nonEditable({ type: 'headerCell', text: headerName })
        const item = findDataForColId(colId, data)
        let value = item?.[key] || ''
        return { type: details.type, value, className: details.className || 'cell', nanToZero: true }
      }),
    ],
    height: 38,
  }
}

function createHeaderRow(name: string, colIds: string[]): Row<RowCells> {
  return {
    rowId: `header-${name}`,
    cells: [
      ...colIds.map<RowCells>((colId, idx) => {
        const text = idx === 0 ? name : ''
        return nonEditable({ type: 'headerCell', text, className: 'header-section' })
      }),
    ],
    height: 50,
  }
}

const getRows = (data: FinancialDataV2[], cols: InternalCol[]): Row<RowCells>[] => {
  const colIds = cols.map((col) => col.columnId.toString())
  return [
    headerRow(cols),
    createHeaderRow('Financials', colIds),
    getRowFor(data, colIds, 'totalRevenue'),
    getRowFor(data, colIds, 'totalRevGrowthPercent'),
    getRowFor(data, colIds, 'startingARR'),
    getRowFor(data, colIds, 'newSalesARR'),
    getRowFor(data, colIds, 'expansionARR'),
    getRowFor(data, colIds, 'resurrectedARR'),
    getRowFor(data, colIds, 'contractionARR'),
    getRowFor(data, colIds, 'churnedARR'),
    getRowFor(data, colIds, 'totalARR'),
    getRowFor(data, colIds, 'arrGrowthPercent'),
    getRowFor(data, colIds, 'totalGrossProfit'),
    getRowFor(data, colIds, 'grossMarginPercent'),
    getRowFor(data, colIds, 'totalEBITDA'),
    getRowFor(data, colIds, 'eBITDAMarginPercent'),
    getRowFor(data, colIds, 'totalFCF'),
    getRowFor(data, colIds, 'fcfMarginPercent'),
    createHeaderRow('Operating Expense Metrics', colIds),
    getRowFor(data, colIds, 'cogs'),
    getRowFor(data, colIds, 'snmExpenses'),
    getRowFor(data, colIds, 'rndExpenses'),
    getRowFor(data, colIds, 'gnaExpenses'),
    getRowFor(data, colIds, 'operatingExpenses'),
    getRowFor(data, colIds, 'gnaAsPercentOfRev'),
    getRowFor(data, colIds, 'snmAsPercentOfRev'),
    getRowFor(data, colIds, 'rndAsPercentOfRev'),
    createHeaderRow('Growth Efficiency Metrics', colIds),
    getRowFor(data, colIds, 'grossRetentionPercent'),
    getRowFor(data, colIds, 'netRetentionPercent'),
    getRowFor(data, colIds, 'salesEfficiencyMultiple'),
    getRowFor(data, colIds, 'netNewARR'),
    getRowFor(data, colIds, 'netNewARRGrowthPercent'),
    getRowFor(data, colIds, 'netNewARRYoYGrowthPercent'),
    getRowFor(data, colIds, 'netNewARRL3MCMGRPercent'),
    getRowFor(data, colIds, 'netNewARRL12MCMGRPercent'),
    getRowFor(data, colIds, 'netNewARRL6MCMGRPercent'),
    createHeaderRow('Efficiency Metrics', colIds),
    getRowFor(data, colIds, 'arrPerEmployee'),
    getRowFor(data, colIds, 'annualizedOpexPerEmployee'),
    getRowFor(data, colIds, 'burnMultiple'),
    getRowFor(data, colIds, 'operatingIncomePercent'),
    getRowFor(data, colIds, 'ruleOf40LTMFCF'),
    getRowFor(data, colIds, 'ruleOf40LTMEbitda'),
    getRowFor(data, colIds, 'implied5yrLTVPerCAC'),
    getRowFor(data, colIds, 'magicNumber'),
    getRowFor(data, colIds, 'cacPayback'),
    getRowFor(data, colIds, 'customers'),
    getRowFor(data, colIds, 'customersGrowth'),
    getRowFor(data, colIds, 'customersYoYGrowth'),
    getRowFor(data, colIds, 'customersL3MCMGR'),
    getRowFor(data, colIds, 'customersL6MCMGR'),
    getRowFor(data, colIds, 'customersL12MCMGR'),
    getRowFor(data, colIds, 'newCustomers'),
    getRowFor(data, colIds, 'churnedCustomers'),
    getRowFor(data, colIds, 'quickRatio'),
    getRowFor(data, colIds, 'totalHeadcount'),
    getRowFor(data, colIds, 'totalHeadcountGrowth'),
    getRowFor(data, colIds, 'totalHeadcountYoYGrowth'),
    getRowFor(data, colIds, 'totalHeadcountL3MCMGR'),
    getRowFor(data, colIds, 'totalHeadcountL6MCMGR'),
    getRowFor(data, colIds, 'totalHeadcountL12MCMGR'),
    getRowFor(data, colIds, 'rndHeadcount'),
    getRowFor(data, colIds, 'cogsHeadcount'),
    getRowFor(data, colIds, 'snmHeadcount'),
    getRowFor(data, colIds, 'gnaHeadcount'),
  ]
}

function findDataForColId(colId: string, data: FinancialDataV2[]) {
  const type = getTypeFromColId(colId)
  if (type === 'ltm' || type === 'ntm') return data.find((item) => item.type === type)
  const [year, quarter, month] = getYearQuarterMonth(colId)
  const item = data.find((item) => item.year === year && (type === 'yearly' || (type === 'quarterly' && item.quarter === quarter) || (type === 'monthly' && item.month === month)))
  return item
}

function getYearQuarterMonth(colId: string): [number, number | undefined, number | undefined] {
  const [year, quarter, month] = colId.split('-')
  const outQuarter = quarter ? parseInt(quarter[1]) : undefined
  const outMonth = month ? parseInt(month) : undefined
  return [parseInt(year), outQuarter, outMonth]
}

function transpose(a) {
  return Object.keys(a[0]).map((c) => {
    let ret = a.map((r) => r[c])
    ret.unshift(c)
    return ret
  })
}

function getTypeFromColId(id: string): DateTypes {
  if (id === 'ltm' || id === 'ntm') return id
  const parts = id.split('-')
  if (parts.length === 1) return 'yearly'
  if (parts.length === 2) return 'quarterly'
  return 'monthly'
}

type FinancialInputProps = {
  submit: (data: FinancialDataV2[]) => Promise<void>
  initState: FinancialDataV2[]
}

const FinancialInput: FunctionComponent<FinancialInputProps> = ({ submit, initState }) => {
  const [data, setData] = useState<FinancialDataV2[]>([...initState])
  const [period, setPeriod] = useState<DateTypes>('yearly')
  const [sending, setSending] = useState<boolean>(false)
  const location = useLocation()
  // init cols to only show years and LTM/NTM
  const [columns, rows] = useMemo(() => {
    const cols = getColumns().filter((col) => col.type === period || col.type === 'header')
    return [cols, getRows(data, cols)]
  }, [period, data])

  const handleChanges = (changes: CellChange<RowCells>[]) => {
    let updateData = [...data]

    changes.forEach((change) => {
      const fieldName = change.rowId
      const colId = change.columnId.toString()
      const oldItem = findDataForColId(colId, updateData)

      let newValue = (change as CellChange<any>).newCell.value
      if (change.type === 'percentCell') {
        newValue = newValue / 100
      }
      if (!oldItem) {
        const type = getTypeFromColId(colId)
        const [year, quarter, month] = getYearQuarterMonth(colId)
        updateData.push({
          type,
          year,
          quarter: type === 'quarterly' ? quarter : undefined,
          month: type === 'monthly' ? month : undefined,
          [fieldName]: newValue,
        })
      } else {
        updateData = updateData.map((item) => {
          if (item === oldItem) {
            return {
              ...item,
              [fieldName]: newValue,
            }
          }
          return item
        })
      }
    })
    setData(updateData)
  }

  const handleDelete = useCallback(() => {
    submit([])
      .then(() => {
        setData([])
      })
      .catch((e) => console.error('delete error', e))
  }, [submit])

  const exportExcel = useCallback(() => {
    const cols = getColumns().filter((col) => col.type !== 'header')

    const fullData = cols.map((col) => {
      const { year, quarter, month, type } = col
      return (
        data.find((item) => {
          if (type === 'ltm' || type === 'ntm') {
            return item.type === type
          }
          const sameYear = item.year === year
          const quarterMatch = sameYear && item.quarter === quarter
          const monthMatch = sameYear && item.month === month
          if (type === 'yearly') {
            return sameYear
          }
          if (type === 'quarterly') {
            return quarterMatch
          }
          if (type === 'monthly') {
            return monthMatch
          }
          throw new Error('unknown type')
        }) || {
          type,
          year,
          quarter,
          month,
        }
      )
    })
    const { metricsOutput, pnlOutput } = fullData.reduce(
      (p, c) => {
        const metric: MetricsOutputKeys = {}
        const pnl: PNLOutputKeys = {}

        Object.entries(keyToHeader).forEach(([key, { displayName, sheet }]) => {
          if (sheet === 'metricsOutput') {
            metric[displayName] = c[key] ?? undefined
          } else if (sheet === 'pnlOutput') {
            pnl[displayName] = c[key] ?? undefined
          }

          return p
        })

        p.metricsOutput.push({
          Date: getDateString(c),
          ...metric,
        })
        p.pnlOutput.push({
          Date: getDateString(c),
          ...pnl,
        })
        return p
      },
      { metricsOutput: [], pnlOutput: [] } as { metricsOutput: FinOutputs[]; pnlOutput: FinOutputs[] }
    )

    exportMultiExcel({
      filename: 'Financials.xlsx',
      allData: [
        { sheetName: 'Metrics Output', data: transpose(metricsOutput) },
        { sheetName: 'P&L Output', data: transpose(pnlOutput) },
      ],
    })
  }, [data])

  return (
    <>
      {location.pathname.includes('financials') && <CompanyHeader />}
      <GlassCard
        header='Financials'
        level={0}
        headerButtons={
          <Flex>
            <SelectField label='Period' labelHidden size='small' value={period} onChange={(e) => setPeriod(e.target.value as any)}>
              <option value='yearly'>Yearly</option>
              <option value='quarterly'>Quarterly</option>
              <option value='monthly'>Monthly</option>
              <option value='ntm'>NTM</option>
              <option value='ltm'>LTM</option>
            </SelectField>
            <Button size='small' variation='destructive' onClick={handleDelete}>
              Delete Data
            </Button>
            <ExportButton action={exportExcel} />
            <Button
              isLoading={sending}
              loadingText='Saving...'
              variation='primary'
              size='small'
              className='save'
              onClick={() => {
                setSending(true)
                submit(data)
                  .catch((e) => console.error('submit error', e))
                  .finally(() => setSending(false))
              }}
            >
              Save Changes
            </Button>
          </Flex>
        }
      >
        <ScrollView className='financialsGrid'>
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges as any}
            customCellTemplates={{
              currencyCell: new CurrencyCellTemplate(),
              multipleCell: new MultipleCellTemplate(),
              percentCell: new PercentCellTemplate(),
              headerCell: new HeaderCellTemplate(),
            }}
            enableRangeSelection
            enableFillHandle
            stickyLeftColumns={1}
          />
        </ScrollView>
      </GlassCard>
    </>
  )
}

export default FinancialInput
