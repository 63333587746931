import { Button, Flex, Table, TableBody, TableCell, TableHead, TableRow, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Select from 'react-select'
import GlassCard from '../figma-components/GlassCard'
import { ProductInfo } from '../models'
import { AiOutlineTag } from '../ui-components'
import Editable from './Editable'
import ProductTable from './ProductTable'

interface ProductsTableProps {
  products: ProductInfo[]
  isEditing: boolean
  onSave: (products: ProductInfo[]) => Promise<void>
}
// also update "companyInfoAi.js"
const taxonomies = [
  'AI-Based Application Threat Defense',
  'AI Security',
  'Application Isolation',
  'Process Isolation/Virtualization',
  'Application Security and Control',
  'DevOps Security',
  'Secure Code Review / SAST / DAST',
  'Web Application Firewalls (WAF)',
  'Web Application Scanning services',
  'Web Application Scanning Software',
  'Web Threat Disruption Technology',
  'Enterprise App-Layer Protection',
  'Runtime Application Self-Protection (RASP)',
  'Cloud Access Security Brokers',
  'Cloud Authentication platform',
  'Cloud Application Discovery',
  'Cloud Compliance and Security Risk Platforms',
  'Cloud Protection Gateways',
  'Cloud/Virtualized Firewalls',
  'Compliance Oriented Private Cloud Hosting (COPCH)',
  'Big Data Encryption',
  'Cloud/SAAS Encryption',
  'Database Encryption',
  'Email Encryption',
  'Encrypted USB Storage',
  'File/Folder Encryption',
  'Network communication encryption',
  'Quantum Encryption',
  'Tokenization',
  'Whole Disk Encryption',
  'Big Data Security',
  'Database Security',
  'Data Governance and Classification',
  'Data masking',
  'Data Retention and Archiving',
  'DSPM - Data Security Posture Management',
  'File Analysis / Sanitization',
  'File Integrity Monitoring',
  'Network-Based Data Cleansing',
  'Secure data collaboration',
  'Software Defined Storage Security',
  'Anti-Malware',
  'Anti-Spam',
  'Anti-Virus',
  'Browser Security',
  'Endpoint Detection and Response (EDR)',
  'Endpoint Intrusion Prevention',
  'Endpoint Sandboxes',
  'Endpoint Sandboxes and Virtualized Containers',
  'Enterprise Mobility Management and MDM',
  'Exploit Technique Prevention Software',
  'Fraud/Bot/Webscrape Prevention',
  'Malware Analysis',
  'Phishing / Ransomware Protection',
  'Policy Assessment and System Configuration Management',
  'Sandbox Software',
  'Secured Mobile',
  'Secure Email',
  'Secure Web',
  'Unauthorized Endpoint Connection Prevention (UECP)',
  'Access Right Management',
  'Active Directory Defense',
  'AD/Unix bridge',
  'Biometrics Authentication',
  'Contextual authentication',
  'Enterprise single sign-on (ESSO)',
  'eSignatures',
  'Externalized authorization management (EAM)',
  'IAM as a Service',
  'Identity governance and administration',
  'Privileged account management (PAM)',
  'Tokens Authentication',
  'User Privilege Management',
  'Wide-focus Authentication',
  'Automotive Security',
  'Cloud-Based Remote Access VPN',
  'Denial of Service Protection',
  'DNS-based Threat Prevention/Detection',
  'Firewall Workflow - Modeling & Change Management',
  'Health Care - Medical',
  'Intellectual Property and Digital Rights Management (DRM)',
  'Interception',
  'Internet of Things',
  'Network Access Control (NAC)',
  'Network Anonymization & Consumer VPN Services',
  'Network Firewalls',
  'Network Intrusion Prevention',
  'OT - SCADA security',
  'Penetration Testing',
  'Personal Firewall',
  'Secure Access Service Edge (SASE)',
  'Software Defined Perimeter',
  'Software Defined WAN (SDwan)',
  'SSL Inspection Appliances',
  'Virtual Container Security',
  'Virtualization Security',
  'Virtual Private Networking (VPN/SSL VPN)',
  'Website & Ad Malware Monitoring',
  'Awareness and Training',
  'Cloud & Container Compliance Assessment',
  'Cyber Risk Insurance',
  'Governance Risk and Compliance',
  'Information Security Audit and Consulting',
  'Legal and regulatory information governance',
  'Managed Detection & Response (MDR)',
  'Operational Risk Analytics',
  'Privacy',
  'Public Certificate Authorities',
  'Risk Posture - Security monitoring and presentation',
  'Security Policy and Response Orchestration Platforms',
  'Security Ratings',
  'All-in-One Security',
  'BAS- Breach and Attack Simulation',
  'Brand & Attack Target Monitoring',
  'Case Management System',
  'change tracking and monitoring',
  'Collaborative Threat Investigation Platforms',
  'DNS and IP Blacklist database',
  'E-Commerce Security',
  'eDiscovery',
  'File and Endpoint Forensics',
  'Financial Fraud detection',
  'Honeypot online database',
  'Incident management',
  'Log Management',
  'Malware Analysis as a Service',
  'Malware Infection Analysis and Monitoring Services',
  'Managed Security Service (MSSP)',
  'Mobile Banking Security',
  'Network-Based Sandbox',
  'Network Packet Brokers',
  'Network Security as a Service',
  'Network Traffic Analysis and Forensics',
  'Payment system security',
  'Protection against insiders',
  'Security Analytics',
  'Security as a Service',
  'Security Automation',
  'Security database (malware and exploits)',
  'Security Operations Analytics',
  'Security Operations Automation Platforms (SOAPS)',
  'Security patch management',
  'SIEM',
  'SOAR - Security Incident Response Platforms (SIRP)',
  'Standalone Threat Intelligence Defense Solutions (STIDS)',
  'Threat Deception Technologies',
  'Threat Intelligence Platforms (TIPs)',
  'Threat Intelligence Services',
  'United Threat Management (UTM)',
  'User and Entity Behavior Analytics (UEBA)',
  'Vulnerability Assessment',
  'Vulnerability Research',
  'Vulnerability Resolution Platforms',
  'Attack Surface Management',
  'Zero Trust Network Access',
  'Data Loss Prevention (DLP)',
  'Purple Teaming',
  'Red Teaming',
  'Blue Teaming',
  'Supply Chain Security',
  'Digital Identity Verification',
  'Post-Quantum Cryptography',
  'API Security',
  'Account Take-Over',
  'Cryptographic Providers',
  'Endpoint Data Loss Prevention (DLP)',
  'Network Data Loss Prevention (DLP)',
  'Content Disarm and Reconstruction (CDR)',
]

const ProductsReview: FunctionComponent<ProductsTableProps> = ({ products: initProducts, isEditing, onSave }) => {
  const [products, setProducts] = useState<ProductInfo[]>(initProducts)
  const [saving, setSaving] = useState<boolean>(false)
  const tag = (text: string) => (
    <Flex alignItems='center' gap='4px' className='taxonomyTag' width='fit-content' key={text}>
      <AiOutlineTag /> <Text>{text}</Text>
    </Flex>
  )

  const cellTopLeftStyle = { verticalAlign: 'top', textAlign: 'left' } as any
  function rowForKey(label: string, key: string, { isMarkdown = true, removeHeaders = false, isArray = false } = {}) {
    return (
      <TableRow key={label}>
        <TableCell as='th' key={label + 'label'}>
          {label}
        </TableCell>
        {products.map((product) => {
          let value = product[key] || 'N/A'
          if (key === 'features') {
            value = product.featuresV2 || product.features || 'N/A'
          }
          if (removeHeaders) {
            value =
              value
                .split('\n')
                .filter((i) => /^[ \t]*[-*] .*(\n[ \t]+.*)*(?=\n\n|$)/gm.test(i))
                .map((i) => i.replace(/\*\*/g, ''))
                .join('\n') ||
              value.replace(/\*\*/g, '') ||
              value
          }
          if (isArray) {
            value = value.map((i) => `* ${i}`).join('\n')
            isMarkdown = true
          }
          return (
            <TableCell style={cellTopLeftStyle} key={label + key + product.name}>
              <Editable
                value={value || 'N/A'}
                isEditing={isEditing}
                onChange={function (v: string): void {
                  const newProducts = [...products]
                  const index = newProducts.findIndex((cs) => cs.name === product.name)
                  newProducts[index][key] = v
                  setProducts(newProducts)
                }}
              />
            </TableCell>
          )
        })}
      </TableRow>
    )
  }

  if (!isEditing) return <ProductTable products={initProducts} />

  return (
    <Flex direction='column'>
      <Button
        isLoading={saving}
        variation='primary'
        onClick={() => {
          setSaving(true)
          return onSave(products)
            .catch((e) => console.error('onSave error', e))
            .finally(() => setSaving(false))
        }}
      >
        SAVE
      </Button>
      <Button
        isLoading={saving}
        onClick={() => {
          setProducts([
            ...products,
            {
              name: 'New Product',
              shortDescription: 'N/A',
              revenueModel: 'N/A',
              targetCustomerProfile: 'N/A',
              markets: [''],
              features: 'N/A',
              deliveryModel: 'N/A',
              sources: [''],
            },
          ])
        }}
      >
        Add new Product
      </Button>
      <GlassCard header='Products and Services' className='overflow'>
        <Table size='small' className='outputTable'>
          <TableHead>
            <TableRow>
              <TableCell as='th' key='firstCell'></TableCell>
              {products.map((product, i) => {
                return (
                  <TableCell style={cellTopLeftStyle} key={i} as='th' minWidth='400px'>
                    <Editable
                      value={product.name || 'N/A'}
                      isEditing={isEditing}
                      onChange={function (v: string): void {
                        const newProducts = [...products]
                        const index = newProducts.findIndex((cs) => cs.name === product.name)
                        const key: string = 'name'
                        newProducts[index][key] = v
                        setProducts(newProducts)
                      }}
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell as='th'></TableCell>
              {products.map((product, i) => {
                return (
                  <TableCell style={cellTopLeftStyle} key={i} as='th' minWidth='400px'>
                    <Button
                      isLoading={saving}
                      variation='destructive'
                      onClick={() => {
                        const newProducts = [...products]
                        newProducts.splice(i, 1)
                        setProducts(newProducts)
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell as='th'>Taxonomy</TableCell>
              {products.map((product) => {
                return (
                  <TableCell style={cellTopLeftStyle}>
                    <Flex wrap='wrap' gap='4px'>
                      {product.taxonomiesV2}
                      <Select
                        styles={{
                          container: (base) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                        isMulti={true}
                        value={product.taxonomiesV2?.map((i) => ({ label: i, value: i })) || []}
                        onChange={(e) => {
                          const update = products.map((i) => {
                            if (i.name === product.name) {
                              return {
                                ...i,
                                taxonomiesV2: e.map((t) => t.value) || [],
                              }
                            }
                            return i
                          })
                          setProducts(update)
                        }}
                        options={taxonomies.map((t) => ({ label: t, value: t }))}
                      />
                    </Flex>
                  </TableCell>
                )
              })}
            </TableRow>
            {rowForKey('Description', 'shortDescription')}
            {rowForKey('Revenue Model', 'revenueModel', { removeHeaders: true })}
            {rowForKey('Target Customers', 'targetCustomerProfile', { removeHeaders: true })}
            <TableRow>
              <TableCell as='th'>Markets</TableCell>
              {products.map((product) => {
                return (
                  <TableCell>
                    <Flex wrap='wrap' gap='4px'>
                      <ReactMarkdown>{product.markets?.map((i) => `* ${i}`).join('\n') || 'N/A'}</ReactMarkdown>
                      <Select
                        styles={{
                          container: (base) => ({
                            ...base,
                            width: '100%',
                          }),
                        }}
                        isMulti={true}
                        value={product.markets?.map((i) => ({ label: i, value: i })) || []}
                        onChange={(e) => {
                          const update = products.map((i) => {
                            if (i.name === product.name) {
                              return {
                                ...i,
                                markets: e.map((t) => t.value) || [],
                              }
                            }
                            return i
                          })
                          setProducts(update)
                        }}
                        options={markets.map((t) => ({ label: t, value: t }))}
                      />
                    </Flex>
                  </TableCell>
                )
              })}
            </TableRow>

            {rowForKey('Features', 'features', { removeHeaders: true })}
            {rowForKey('Delivery Model', 'deliveryModel', { removeHeaders: true })}
            {rowForKey('Sources', 'sources')}
          </TableBody>
        </Table>
      </GlassCard>
    </Flex>
  )
}

export default ProductsReview

// ALSO UPDATE BACKEND
// server/src/services/companyInfoAI.js
const markets = [
  'Cybersecurity Insurance',
  'Serverless Architecture',
  'Bot Services',
  'Conversational AI',
  'NLP in Education',
  'Chatbot',
  'Data Masking',
  'Security Automation',
  'User Provisioning',
  'Personal Identity Management',
  'Certificate Authority',
  'User Activity Monitoring',
  'Payment Security',
  'Endpoint Detection and Response',
  'Cloud Infrastructure Entitlement Management (CIEM)',
  'Application Security',
  'Industrial Control Systems (ICS) Security',
  'Risk-based Authentication',
  'Managed Detection and Response (MDR)',
  'Security Policy Management',
  'Database Security',
  'Container Security',
  'DDoS Protection and Mitigation Security',
  'Captive Portal',
  'Cloud Endpoint Protection',
  'Facial Recognition',
  'Multi-Factor Authentication',
  'Security Information and Event Management',
  'Security Orchestration',
  'Anomaly Detection',
  'Software-Defined Networking',
  'Incident Response',
  'Third-Party Risk Management',
  'Unified Endpoint Management',
  'Embedded Security',
  'Cybersecurity Mesh',
  'Automotive Cybersecurity',
  'Cloud Security',
  'Runtime Application Self-Protection',
  'Cloud Workload Protection',
  'Endpoint Security',
  'SOC as a Service',
  'Identity and Access Management (IAM)',
  'Cyber Security',
  'Multi-cloud Security',
  'Passwordless Authentication',
  'Password Management',
  'Security and Vulnerability Management',
  'Mobile Data Protection',
  'Digital Signature',
  'Identity Governance and Administration',
  'Threat Modeling Tools',
  'Data Center Physical Security',
  'Cognitive Security',
  'Cloud Security Posture Management',
  'Firewall as a Service',
  'Digital Vault',
  'Botnet Detection',
  'Network Function Virtualization (NFV)',
  'Quantum Cryptography',
  'Data Protection',
  'Security Solutions',
  'Security System Integrators',
  'Security Orchestration, Automation and Response (SOAR)',
  '5G Security',
  'Mobile Application Security',
  'Security Assessment',
  'Cloud-native Application Protection Platform (CNAPP)',
  'Messaging Security',
  'Global Smartphone Security',
  'Software Composition Analysis',
  'Cloud Application Security',
  'Managed Domain Name System (DNS) Services',
  'Supply Chain Security',
  'Mobile Device Management',
  'Data Governance',
  'Security Advisory Services',
  'Data Center Logical Security',
  'Key Management as a Service',
  'Africa Cyber Security',
  'Encryption Software',
  'Virtualization Security',
  'Malware Analysis',
  'Fraud Detection and Prevention',
  'Sandboxing',
  'Extended Detection and Response',
  'Security Assertion Markup Language (SAML) Authentication',
  'Ransomware Protection',
  'CDN Security',
  'Consumer IAM',
  'Perimeter Security',
  'Industrial Security Systems',
  'Intrusion Detection and Prevention Systems',
  'Dynamic Application Security Testing',
  'Data Center Security',
  'Decentralized Identity',
  'Cloud Database and DBaaS',
  'Security Assurance',
  'Threat Intelligence',
  'Cloud Access Security Brokers',
  'Data Security as a Service',
  'Confidential Computing',
  'Unified Threat Management',
  'Cloud Encryption',
  'Network Forensics',
  'Secure Web Gateway',
  'Privileged Identity Management',
  'Security Testing',
  'Deception Technology',
  'Biometric-as-a-Service',
  'Serverless Security',
  'DDI',
  'Proactive Security',
  'IoT Security',
  'Software-Defined Wide Area Network (SD-WAN)',
  'Mobile User Authentication',
  'Mobile Content Management',
  'SASE',
  'Web Filtering',
  'POS Security',
  'Healthcare Cybersecurity',
  'Cloud Compliance',
  'Enterprise Firewall',
  'Email Encryption',
  'Virtual Private Cloud',
  'Wireless Network Security',
  'BFSI Security',
  'Software-Defined Data Center',
  'Public Key Infrastructure (PKI)',
  'IoT IAM',
  'Middle East Cloud Applications',
  'IDaaS',
  'Middle East and Africa Cybersecurity',
  'Quantum Computing Software',
  'Information Security Consulting',
  'Unified Monitoring',
  'Big Data Security',
  'Cloud Based Data Management Services',
  'Patch Management',
  'Satellite IoT',
  'Smart Grid Security',
  'Network Telemetry',
  'Database Encryption',
  'Digital Transformation',
  'Identity Verification',
  'Monitoring Tools',
  'Information Rights Management',
  'Physical Identity and Access Management',
  'Private 5G',
  'GDPR Services',
  'Insurance Fraud Detection',
  'Business Email Compromise (BEC)',
  'Cloud Storage',
  'Customer Experience Management',
  'Risk Analytics',
  'Automated Breach and Attack Simulation',
  'Bot Security',
  'Security Analytics',
  'Electronic Access Control Systems',
  'Blockchain Identity Management',
  'Operational Technology (OT) Security',
  'Network Probe',
  'Digital Forensics',
  'Content Disarm and Reconstruction',
  'Log Management',
  'Managed Security Services',
  'Web Application Firewall',
  'Network Security Software',
  'Transaction Monitoring',
  'Tokenization',
  'Authentication Services',
  'Adaptive Security',
  'Software Defined Perimeter',
  'Lawful Interception',
  'Penetration Testing',
]
