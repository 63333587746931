import { Flex } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import CandleStickView, { ChartData as CandleStickViewData, ValueTypes } from './CandleStickView'
import InputTableView from './InputTableView'

interface BenchmarkingDashboardProps {
  data: {
    [bucket: string]: ChartData[]
  }
  targetData: ChartData
}

export type ChartData = {
  company: string
  ntmRevGrowth?: number
  ltmRevGrowth?: number
  ltmARRGrowth?: number
  smAsPofRev?: number
  smYield?: number
  ltv_CAC?: number
  netNewARRGrowth?: number
  netDollarRetention?: number
  grossDollarRetention?: number
  grossMargin?: number
  ebitdaMargin?: number
  ruleOf40OpInMargin?: number
}

const BenchmarkingDashboard: FunctionComponent<BenchmarkingDashboardProps> = ({ data, targetData }) => {
  const chartData: Record<string, { data: CandleStickViewData[]; target: CandleStickViewData; label: string; valueType: ValueTypes }> = {
    ntmRevGrowth: { data: [], target: { name: targetData.company, value: targetData.ntmRevGrowth || 0 }, label: 'NTM Rev Growth', valueType: 'percent' },
    ltmRevGrowth: { data: [], target: { name: targetData.company, value: targetData.ltmRevGrowth || 0 }, label: 'LTM Rev Growth', valueType: 'percent' },
    ltmARRGrowth: { data: [], target: { name: targetData.company, value: targetData.ltmARRGrowth || 0 }, label: 'LTM ARR Growth', valueType: 'percent' },
    smAsPofRev: { data: [], target: { name: targetData.company, value: targetData.smAsPofRev || 0 }, label: 'S&M as % of Rev', valueType: 'percent' },
    smYield: { data: [], target: { name: targetData.company, value: targetData.smYield || 0 }, label: 'S&M Yield', valueType: 'percent' },
    ltv_CAC: { data: [], target: { name: targetData.company, value: targetData.ltv_CAC || 0 }, label: 'LTV / CAC', valueType: 'multiple' },
    netNewARRGrowth: { data: [], target: { name: targetData.company, value: targetData.netNewARRGrowth || 0 }, label: 'Net New ARR Growth', valueType: 'percent' },
    netDollarRetention: { data: [], target: { name: targetData.company, value: targetData.netDollarRetention || 0 }, label: 'Net $ Retention', valueType: 'percent' },
    grossDollarRetention: { data: [], target: { name: targetData.company, value: targetData.grossDollarRetention || 0 }, label: 'Gross $ Retention', valueType: 'percent' },
    grossMargin: { data: [], target: { name: targetData.company, value: targetData.grossMargin || 0 }, label: 'Gross Margin', valueType: 'percent' },
    ebitdaMargin: { data: [], target: { name: targetData.company, value: targetData.ebitdaMargin || 0 }, label: 'EBITDA Margin', valueType: 'percent' },
    ruleOf40OpInMargin: { data: [], target: { name: targetData.company, value: targetData.ruleOf40OpInMargin || 0 }, label: 'Rule of 40 (Op Margin)', valueType: 'percent' },
  }

  for (const bucket in data) {
    for (const row of data[bucket]) {
      for (const key in chartData) {
        if (!row) continue
        const value = row[key]
        if (typeof value !== 'number') continue
        if (value === Infinity) continue
        chartData[key].data.push({
          name: row.company,
          value: row[key],
        })
      }
    }
  }

  const chartGroup = ({ groupData, groupLabel }) => {
    return (
      <InputTableView tableTitle={groupLabel} isVisible={true}>
        <Flex direction='column' minHeight='450px' height='100%' maxHeight='50vh'>
          <Flex direction='row' flex='1' padding='1rem 0'>
            <CandleStickView data={groupData[0].data} target={groupData[0].target} label={groupData[0].label} valueType={groupData[0].valueType} />
            <CandleStickView data={groupData[1].data} target={groupData[1].target} label={groupData[1].label} valueType={groupData[1].valueType} />
            <CandleStickView data={groupData[2].data} target={groupData[2].target} label={groupData[2].label} valueType={groupData[2].valueType} />
          </Flex>
        </Flex>
      </InputTableView>
    )
  }
  return (
    <Flex direction='row'>
      <Flex direction='column' flex='1'>
        {chartGroup({ groupData: [chartData.ntmRevGrowth, chartData.ltmRevGrowth, chartData.ltmARRGrowth], groupLabel: 'Rev Growth' })}
        {chartGroup({ groupData: [chartData.netNewARRGrowth, chartData.netDollarRetention, chartData.grossDollarRetention], groupLabel: 'Booking & Retention' })}
      </Flex>
      <Flex direction='column' flex='1'>
        {chartGroup({ groupData: [chartData.smAsPofRev, chartData.smYield, chartData.ltv_CAC], groupLabel: 'S&M Efficiency Metrics' })}
        {chartGroup({ groupData: [chartData.grossMargin, chartData.ebitdaMargin, chartData.ruleOf40OpInMargin], groupLabel: 'Operational Efficiency' })}
      </Flex>
    </Flex>
  )
}

export default BenchmarkingDashboard
