import { Button, Flex, Table, TableBody, TableCell, TableRow, TextAreaField } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import GlassCard from '../figma-components/GlassCard'
import { DealLogHeaders } from '../hooks/useDealLog'
import { MeetingNotesHeaders } from '../hooks/useMeetingNotes'
import { cleanNotes } from '../services/aiRuns'
import { restAPIRequest } from '../services/restApiRequest'

type DealLogProps = {
  dealLog: DealLogHeaders[]
  companyName: string
}

const DealLogView: FunctionComponent<DealLogProps> = ({ dealLog, companyName }) => {
  const [isEditing, setIsEditable] = useState<Record<number, boolean>>({})
  const [updates, setUpdate] = useState<Record<number, MeetingNotesHeaders>>({})
  const [isSaving, setSaving] = useState<Record<number, boolean>>({})
  const deals = dealLog.map((meetingNote) => ({ ...meetingNote, ...updates[meetingNote.index] }))
  const hasChanges = (index: number) => Object.keys(updates[index] || {}).length > 0

  async function save(index) {
    const body = {
      ...updates[index],
    }
    if (!body) return
    if (isSaving[index]) return
    setSaving({
      ...isSaving,
      [index]: true,
    })

    try {
      if (body['Meeting Notes']) {
        const cleaned = await cleanNotes(body['Meeting Notes'])
        body['Playbook:Meeting Notes'] = cleaned
      }
      const res = await restAPIRequest<{ index: number }>({
        path: `companyProfile/${companyName}/dealLog`,
        method: 'post',
        body,
      })
      const newUpdates = { ...updates }
      newUpdates[res.index] = { ...body, index: res.index }
      delete newUpdates[-1]
      setUpdate(newUpdates)
    } catch (error) {
      console.error('Failed to save notes', error)
      alert('Failed to save notes, please try again in a moment')
    } finally {
      setSaving({
        ...isSaving,
        [index]: false,
      })
    }
  }

  const editButton = (index) => {
    return (
      <Button
        size='small'
        isLoading={isSaving[index]}
        onClick={async (e) => {
          e.preventDefault()
          e.stopPropagation()
          try {
            if (isEditing[index]) {
              if (hasChanges(index)) save(index)
            }
            setIsEditable({
              ...isEditing,
              [index]: !isEditing[index],
            })
          } catch (e) {
            console.error(e)
            alert('Failed to save, please try again in a moment')
          }
        }}
      >
        {isEditing[index] ? (hasChanges(index) ? 'Save' : 'Back') : 'Edit'}
      </Button>
    )
  }
  const updateValue = (index, key) => (v) => {
    setUpdate({
      ...updates,
      [index]: {
        ...updates[index],
        index: index,
        [key]: v.target.value,
      },
    })
  }

  const dealView = (item) => (
    <Flex direction='column' key={item.index}>
      <GlassCard header={`Deal Info`} className='overlayCard' expandable={item.index !== -1 ? true : false} headerButtons={editButton(item.index)}>
        <Table caption='' highlightOnHover={true} variation='bordered' size='small'>
          <TableBody>
            {sortItems(item).map((key, j) => {
              if (['PlaybookID', 'index', 'Company', 'PlaybookDealID'].includes(key)) return null
              let body = <ReactMarkdown>{`${item[key] || ''}`}</ReactMarkdown>
              if (isEditing[item.index]) {
                body = (
                  <Flex>
                    <TextAreaField flex={1} label='' labelHidden size='small' rows={1} value={item[key]} onChange={updateValue(item.index, key)} />
                  </Flex>
                )
              }
              return (
                <TableRow key={j}>
                  <TableCell width={'12rem'} fontWeight={600}>
                    {key}
                  </TableCell>
                  <TableCell>{body}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </GlassCard>
    </Flex>
  )

  return <Flex direction='column'>{deals.map((deal) => dealView(deal))}</Flex>
}

function sortItems(data) {
  const keys = Object.keys(data).sort()
  const out: string[] = []
  keys.forEach((key) => {
    if (key.startsWith('Playbook:')) return
    const playbookKey = `Playbook:${key}`
    if (keys.includes(playbookKey)) {
      out.push(playbookKey)
    }
    out.push(key)
  })
  return out
}

export default DealLogView
