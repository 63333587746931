import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { FundingRounds } from '../types'

type FinancialsRes = {
  financials: FundingRounds[]
}

const MAX_RETRIES = 3
export function useFinancials(opportunityId: string, enabled: boolean = true): [FundingRounds[] | undefined, boolean, Error | undefined] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['financials', opportunityId],
    enabled: enabled && !!opportunityId,
    queryFn: queryFinancialsCalc(opportunityId),
    retry: (failureCount, error) => {
      if (failureCount > MAX_RETRIES) {
        return false
      }

      return true
    },
  })

  return [data?.financials, isLoading, error as Error | undefined]
}

export function queryFinancialsCalc(opportunityId: string) {
  return async () =>
    restAPIRequest<FinancialsRes>({
      path: `deals/${opportunityId}/financials`,
      method: 'get',
    })
}
