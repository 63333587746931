import { restAPIRequest } from './restApiRequest'

type DocLoadRes = {
  documentID: string
}
export async function loadDoc(file: Blob | undefined, opportunityName: string) {
  if (!file) {
    throw new Error('missing file')
  }

  const data = new FormData()

  const fileType = file.type || 'text'
  data.append('file', file)
  data.append('documentType', fileType)
  const { documentID } = await restAPIRequest<DocLoadRes>({
    body: data,
    method: 'post',
    path: 'docs/load',
  })

  data.append('opportunityName', opportunityName)
  data.append('documentID', documentID)
  // @ts-ignore blob does not contain name
  return { documentId: documentID, documentName: file.name, documentType: fileType }
}
