import '@aws-amplify/ui-react/styles.css'
import './styles/app.css'
import './styles/view.css'

import { Alert, Button, Card, Flex, Heading, Loader, View, WithAuthenticatorProps, withAuthenticator } from '@aws-amplify/ui-react'
import { FullStory } from '@fullstory/browser'
import { Amplify } from 'aws-amplify'
import { DataStore, syncExpression } from 'aws-amplify/datastore'
import { useEffect, useState } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import amplifyconfig from './amplifyconfiguration.json'
import { AmplifyUserProvider, ExtendedAmplifyUser, populateUser } from './contexts/amplifyUserContext'
import { CyberProvider } from './contexts/cyberContext'
import { OpportunityProvider } from './contexts/opportunityContext'
import { OrgProvider } from './contexts/orgContext'
import { UserProvider } from './contexts/userContext'
import { CompanyInfoV3 } from './models'
import { getRoutes } from './sitemap'
import { restAPIRequest } from './services/restApiRequest'

Amplify.configure(amplifyconfig)
const existingConfig = Amplify.getConfig()

Amplify.configure({
  ...existingConfig,
  API: {
    ...existingConfig.API,
    REST: {
      ...existingConfig.API?.REST,
      RestAPI: {
        endpoint: window.location.host.includes('localhost') ? 'https://localhost:8989' : 'https://api.playbookmna.com',
        region: 'us-east-1',
      },
    },
  },
})

DataStore.configure({
  syncExpressions: [
    syncExpression(CompanyInfoV3, () => {
      return (info) => info.companyName.eq('')
    }),
  ],
})
const siteMap = getRoutes()

const router = createBrowserRouter(siteMap)

const App = ({ user, signOut }: WithAuthenticatorProps) => {
  const [extendedUser, setExUser] = useState<ExtendedAmplifyUser>()
  useEffect(() => {
    if (!user) return // setExUser({ org: 'offline', groups: [PB_CAP_TABLE_INPUT] } as any)
    restAPIRequest({
      method: 'post',
      path: 'org/assign',
    })
      .then(async () => {
        const exUser = await populateUser(user)
        setExUser(exUser)
      })
      .catch((e) => {
        console.error(e)
        signOut && signOut()
      })
  }, [user])

  if (!extendedUser) return <Loader />
  const org = extendedUser.org
  // @ts-ignore
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'gty2f4r4',
    name: extendedUser.name,
    email: extendedUser.email, // Email address
  }
  // @ts-ignore
  window.pendo.initialize({
    visitor: {
      id: extendedUser.userId,
      email: extendedUser.email,
      full_name: extendedUser.name,
    },
    account: {
      id: org,
    },
  })

  if (!org) {
    return (
      <View>
        <Card variation='elevated'>
          <Flex direction='column'>
            <Alert variation='info' isDismissible={false} hasIcon={true}>
              <Heading level={5}>Please contact rachit@playbookmna.com to enable your account.</Heading>
            </Alert>
            <Button variation='primary' size='small' onClick={signOut}>
              Go Back
            </Button>
          </Flex>
        </Card>
      </View>
    )
  }

  FullStory('setIdentity', {
    uid: extendedUser.username || 'UNKNOWN',
    properties: {
      displayName: extendedUser.name,
      org,
      email: extendedUser.email,
    },
  })

  return (
    <AmplifyUserProvider user={extendedUser}>
      <OrgProvider>
        <UserProvider>
          <OpportunityProvider>
            <CyberProvider>
              <RouterProvider router={router} />
            </CyberProvider>
          </OpportunityProvider>
        </UserProvider>
      </OrgProvider>
    </AmplifyUserProvider>
  )
}

export default withAuthenticator(App)
