import { Flex } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import Dashboard from '../components/Dashboard'
import FeatureRequest from '../components/FeatureRequest'
import { useOrg } from '../contexts/orgContext'
import ProfileSearch from '../components/ProfileSearch'

const CRMPage: FunctionComponent = () => {
  const org = useOrg()
  const url = (org?.dashboards?.find((d) => d.companyName === 'CRM')?.url as string) || ''
  if (!url.length)
    return (
      <Flex justifyContent='center' alignItems='center' height='70%'>
        <FeatureRequest featureName={'crm'} requestMsg='Request CRM to keep track of your deals.' requestedMsg='Sit back while enable this feature for you!' />
      </Flex>
    )
  return (
    <Flex direction='column'>
      <ProfileSearch enableNavigate />
      <Dashboard url={url} />
    </Flex>
  )
}
export default CRMPage
