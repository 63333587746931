import { FunctionComponent } from 'react'

import InputTable from '../components/InputTable'
import '../styles/comps.css'
import { FundingRounds } from '../types'

const Cols = [
  {
    header: 'Investment Date',
    accessorKey: 'date',
    meta: {
      type: 'viewOnlyDate',
    },
  },
  {
    header: 'Group',
    accessorKey: 'group',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Shareholder Name',
    accessorKey: 'shareholderName',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Round',
    accessorKey: 'round',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Type',
    accessorKey: 'type',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Type 2',
    accessorKey: 'type2',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Round Amount',
    accessorKey: 'roundAmount',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
  {
    header: 'Pre-Money Valuation',
    accessorKey: 'preMoneyValuation',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
  {
    header: 'Post-Money Valuation',
    accessorKey: 'postMoneyValuation',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
  {
    header: 'Pre EV / LTM Rev',
    accessorKey: 'preEvLtmRev',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Post EV / LTM Rev',
    accessorKey: 'postEvLtmRev',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Pre EV / NTM Rev',
    accessorKey: 'preEvNtmRev',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Post EV / NTM Rev',
    accessorKey: 'postEvNtmRev',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },

  {
    header: 'Pre EV / LTM GP',
    accessorKey: 'preEvLtmProfit',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Post EV / LTM GP',
    accessorKey: 'postEvLtmProfit',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Pre EV / NTM GP',
    accessorKey: 'preEvNtmProfit',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Post EV / NTM GP',
    accessorKey: 'postEvNtmProfit',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Pre EV / ARR',
    accessorKey: 'preEvArr',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Post EV / ARR',
    accessorKey: 'postEvArr',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Pre EV / ARR FY+1A',
    accessorKey: 'preEvNextArr',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Post EV / ARR FY+1A',
    accessorKey: 'postEvNextArr',
    meta: {
      minWidth: '8rem',
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Gross Dollar Retention',
    accessorKey: 'grossRetentionPercent',
    meta: {
      type: 'viewOnlyPercentage',
    },
  },
  {
    header: 'Net Dollar Retention',
    accessorKey: 'netRetentionPercent',
    meta: {
      type: 'viewOnlyPercentage',
    },
  },
  {
    header: 'ARR Growth % YoY',
    accessorKey: 'arrGrowthPercent',
    meta: {
      type: 'viewOnlyPercentage',
    },
  },
  {
    header: 'LTV / CAC',
    accessorKey: 'implied5yrLTVPerCAC',
    meta: {
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Burn Multiple',
    accessorKey: 'burnMultiple',
    meta: {
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Net New ARR - YoY Growth',
    accessorKey: 'netNewARRYoYGrowthPercent',
    meta: {
      type: 'viewOnlyPercentage',
    },
  },
  {
    header: 'Magic Number',
    accessorKey: 'magicNumber',
    meta: {
      type: 'viewOnlyNumber',
    },
  },
  {
    header: 'Total Customers',
    accessorKey: 'customers',
    meta: {
      type: 'viewOnlyNumber',
    },
  },
  {
    header: 'Gross Margin %',
    accessorKey: 'grossMarginPercent',
    meta: {
      type: 'viewOnlyPercentage',
    },
  },
  {
    header: 'New Sales ARR / S&M Expenses',
    accessorKey: 'salesEfficiencyMultiple',
    meta: {
      type: 'viewOnlyMultiple',
    },
  },
  {
    header: 'Total Raised',
    accessorKey: 'totalRaised',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
]

type FinancialsTableProps = {
  financials: FundingRounds[]
}

const FinancialsTable: FunctionComponent<FinancialsTableProps> = ({ financials }) => {
  return <InputTable data={financials} columns={Cols} enableColumnFilters={false} />
}

export default FinancialsTable
