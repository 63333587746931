import { Loader, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import MeetingNotes from '../components/MeetingNotes'
import { useMeetingNotes } from '../hooks/useMeetingNotes'
import { useInvestments } from '../hooks/useInvestments'
import '../styles/companyOverview.css'
import GlassCard from './GlassCard'
import InvestmentsView from './InvestmentsView'
import { useDealLog } from '../hooks/useDealLog'
import DealLogView from '../components/DealLogView'

interface CompanyOverviewProps {
  companyName: string
  companyLabel: string
  dealId?: string
}

const ExtendProfileView: FunctionComponent<CompanyOverviewProps> = ({ dealId, companyName, companyLabel }) => {
  const [investments, isILoading, iError] = useInvestments({ companyName })
  const [dealLog, isDLLoading, dlError] = useDealLog({ dealId })
  const [meetingNotes, isLoading, error] = useMeetingNotes({ companyName, companyLabel, enabled: !!companyName })
  if (isLoading) return <Loader />
  if ((error && error.message.includes('not enabled')) || (iError && iError.message.includes('not enabled'))) {
    return (
      <GlassCard header='' className='crmCard lastCard'>
        <Text>No CRM information available</Text>
      </GlassCard>
    )
  }

  return (
    <GlassCard header='' className='crmCard lastCard'>
      <InvestmentsView investments={investments} isEditable={false} setUpdate={() => {}} />
      <MeetingNotes meetingNotes={meetingNotes} companyName={companyName} />
      <DealLogView dealLog={dealLog} companyName={companyName} />
    </GlassCard>
  )
}

export default ExtendProfileView
