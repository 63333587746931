import { Flex, Label, Loader, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import CurrencyInput from '../components/CurrencyInput'
import FundingBenchmarkingDashboards from '../components/FundingBenchmarkingDashboards'
import MultipleInput from '../components/MultipleInput'
import NumberInput from '../components/NumberInput'
import PercentInput from '../components/PercentInput'
import GlassCard from '../figma-components/GlassCard'
import { useAllFinancials } from '../hooks/useAllFinancials'
import '../styles/dashboard.css'
import '../styles/grid.scss'
import '../styles/table.css'
import { currencyFormatterShort, multipleFormatter, numberFormatter, percentFormatter, removeDuplicateValues } from '../utils/utils'
import DateInput from '../components/DateInput'
import MissingDataCard from '../components/missingDataCard'

const filters = {
  preMoneyValuation: { displayName: 'Pre-Money Valuation', input: CurrencyInput, formatter: currencyFormatterShort },
  postMoneyValuation: { displayName: 'Post-Money Valuation', input: CurrencyInput, formatter: currencyFormatterShort },
  roundAmount: { displayName: 'Round Amount', input: CurrencyInput, formatter: currencyFormatterShort },
  preEvLtmRev: { displayName: 'Pre EV / LTM Rev', input: MultipleInput, formatter: multipleFormatter },
  postEvLtmRev: { displayName: 'Post EV / LTM Rev', input: MultipleInput, formatter: multipleFormatter },
  preEvLtmProfit: { displayName: 'Pre EV / LTM GP', input: MultipleInput, formatter: multipleFormatter },
  postEvLtmProfit: { displayName: 'Post EV / LTM GP', input: MultipleInput, formatter: multipleFormatter },
  preEvNtmRev: { displayName: 'Pre EV / NTM Rev', input: MultipleInput, formatter: multipleFormatter },
  postEvNtmRev: { displayName: 'Post EV / NTM Rev', input: MultipleInput, formatter: multipleFormatter },
  preEvNtmProfit: { displayName: 'Pre EV / NTM GP', input: MultipleInput, formatter: multipleFormatter },
  postEvNtmProfit: { displayName: 'Post EV / NTM GP', input: MultipleInput, formatter: multipleFormatter },
  preEvArr: { displayName: 'Pre EV / ARR', input: MultipleInput, formatter: multipleFormatter },
  postEvArr: { displayName: 'Post EV / ARR', input: MultipleInput, formatter: multipleFormatter },
  preEvNextArr: { displayName: 'Pre EV / ARR FY+1A', input: MultipleInput, formatter: multipleFormatter },
  postEvNextArr: { displayName: 'Post EV / ARR FY+1A', input: MultipleInput, formatter: multipleFormatter },
  grossRetentionPercent: { displayName: 'Gross Dollar Retention', input: PercentInput, formatter: percentFormatter },
  netRetentionPercent: { displayName: 'Net Dollar Retention', input: PercentInput, formatter: percentFormatter },
  arrGrowthPercent: { displayName: 'ARR Growth % YoY', input: PercentInput, formatter: percentFormatter },
  implied5yrLTVPerCAC: { displayName: 'LTV / CAC', input: MultipleInput, formatter: multipleFormatter },
  burnMultiple: { displayName: 'Burn Multiple', input: MultipleInput, formatter: multipleFormatter },
  netNewARRYoYGrowthPercent: { displayName: 'Net New ARR - YoY Growth', input: PercentInput, formatter: percentFormatter },
  magicNumber: { displayName: 'Magic Number', input: MultipleInput, formatter: multipleFormatter },
  customers: { displayName: 'Total Customers', input: NumberInput, formatter: numberFormatter },
  grossMarginPercent: { displayName: 'Gross Margin %', input: PercentInput, formatter: percentFormatter },
  salesEfficiencyMultiple: { displayName: 'New Sales ARR / S&M Expenses', input: MultipleInput, formatter: multipleFormatter },
  totalRaised: { displayName: 'Total Raised', input: CurrencyInput, formatter: currencyFormatterShort },
}

const MetricsAndBenchmarkingPage: FunctionComponent = () => {
  const [allFinancialsData, isFinLoading, error] = useAllFinancials()
  const [name, setName] = useState<string>()
  // preprare metrics and benchmarking dataset
  const [min, setMin] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 1000000000 }), {}))
  const [max, setMax] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 0 }), {}))

  const [totalMin, setTotalMin] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 1000000000 }), {}))
  const [totalMax, setTotalMax] = useState(Object.keys(filters).reduce((p, c) => ({ ...p, [c]: 0 }), {}))

  const [dates, setDates] = useState<{ min: Date; max: Date; originalMin: Date; originalMax: Date }>({
    originalMin: new Date(),
    originalMax: new Date(),
    min: new Date(),
    max: new Date(),
  })
  const names = removeDuplicateValues(allFinancialsData?.map((row) => row.round || '') || []).map((i) => ({ value: i, label: i }))

  useEffect(() => {
    if (!allFinancialsData) return
    const minDate = new Date('9999-12-31')
    const maxDate = new Date(0)

    allFinancialsData.forEach((row) => {
      const date = new Date(row.date)
      if (date < minDate) minDate.setTime(date.getTime())
      if (date > maxDate) maxDate.setTime(date.getTime())
    })
    const newMin = Object.keys(filters).reduce((p, c) => {
      const min = Math.min(...allFinancialsData.map((row) => row[c]))
      return { ...p, [c]: min }
    }, {})
    const newMax = Object.keys(filters).reduce((p, c) => {
      const max = Math.max(...allFinancialsData.map((row) => row[c]))
      return { ...p, [c]: max }
    }, {})
    setMin(newMin)
    setMax(newMax)
    setTotalMin(newMin)
    setTotalMax(newMax)
    setDates({ min: minDate, max: maxDate, originalMin: minDate, originalMax: maxDate })
  }, [allFinancialsData])

  const metricBenchmarkData = useMemo(() => {
    return (
      allFinancialsData?.filter((row) => {
        if (name && row.round !== name) return false
        if (dates.min !== dates.originalMin && new Date(row.date) < dates.min) return false
        if (dates.max !== dates.originalMax && new Date(row.date) > dates.max) return false
        return Object.keys(filters).every((key) => row[key] >= min[key] && row[key] <= max[key])
      }) || []
    )
  }, [allFinancialsData, dates.max, dates.min, dates.originalMax, dates.originalMin, max, min, name])

  const renderFilter = (key: string) => {
    const filter = filters[key]
    const FilterInput = filter.input
    return (
      <Flex key={key} direction='column' className='filterCard'>
        <Label>{filter.displayName}</Label>
        <Flex>
          <Flex direction='column' gap='0.25rem'>
            <FilterInput label='Min' value={min[key]} onValueChange={(value) => setMin({ ...min, [key]: value })} />
            <small>Total Min: {filter.formatter(totalMin[key])}</small>
          </Flex>
          <Flex direction='column' gap='0.25rem'>
            <FilterInput label='Max' value={max[key]} onValueChange={(value) => setMax({ ...max, [key]: value })} />
            <small>Total Max: {filter.formatter(totalMax[key])}</small>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  if (isFinLoading) return <Loader />
  if (error)
    return (
      <MissingDataCard>
        <Text>We are unable to show this data right now. Please try again later or contact support@playbookmna.com</Text>
      </MissingDataCard>
    )

  return (
    <Flex direction='column'>
      <GlassCard header='Filters' overflow='scroll' expandable={true} initIsExpanded={false} className='overlayCard'>
        <Flex direction='row' wrap='wrap' width='325em' gap='0.6rem'>
          <Flex direction='column' className='filterCard'>
            <Label paddingBottom='1.5rem'>Target Deal</Label>
            <Select
              isClearable={true}
              isLoading={isFinLoading}
              placeholder={'Select...'}
              openMenuOnClick={true}
              styles={{ control: (s) => ({ ...s, width: '20rem' }), menu: (s) => ({ ...s, width: '20rem' }) }}
              options={names}
              value={{ label: name, value: name }}
              onChange={(newValue) => {
                setName(newValue?.label)
              }}
            />
          </Flex>
          <Flex key='date' direction='column' className='filterCard'>
            <Label>Investment Date</Label>
            <Flex>
              <Flex direction='column' gap='0.25rem'>
                <DateInput label='Min' value={dates.min} onChange={(value) => setDates({ ...dates, min: value })} />
                <small>Min: {dates.originalMin.toDateString()}</small>
              </Flex>
              <Flex direction='column' gap='0.25rem'>
                <DateInput label='Max' value={dates.max} onChange={(value) => setDates({ ...dates, max: value })} />
                <small>Max: {dates.originalMax.toDateString()}</small>
              </Flex>
            </Flex>
          </Flex>
          {Object.keys(filters).map((key) => renderFilter(key))}
        </Flex>
      </GlassCard>
      <GlassCard header='Benchmarks' className='overlayCard'>
        <FundingBenchmarkingDashboards data={metricBenchmarkData} />
      </GlassCard>
    </Flex>
  )
}
export default MetricsAndBenchmarkingPage
