import { Flex, Link, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import MyDocuments from '../components/MyDocuments'
import { Opportunity } from '../models'
import { IoSparklesSharp } from '../ui-components'
import GlassCard from './GlassCard'

export type DocTags = 'overview' | 'products'

interface DataSourcesProps {
  websiteSources: string[]
  opportunity?: Opportunity | null
  docTag?: DocTags
}

const DataSourcesView: FunctionComponent<DataSourcesProps> = ({ websiteSources, opportunity, docTag }) => {
  const websiteLinksCard = (
    <GlassCard level={2} header='Websites' flex={1} className='overlayCard'>
      {websiteSources.map((source, i) => (
        <Link isExternal={true} key={i} href={source}>
          {source}
        </Link>
      ))}
    </GlassCard>
  )
  const docCards = docTag && opportunity && <MyDocuments docTag={docTag || ''} opportunity={opportunity} flex={1} level={1} />
  const expandedContent = (
    <Flex direction='column'>
      <Text>Content on this page was generated by AI using the following sources:</Text>
      <Flex>
        {websiteLinksCard}
        {docCards}
      </Flex>
    </Flex>
  )

  const poweredByAI = (
    <Flex className='aiBadge'>
      <IoSparklesSharp />
      Powered by AI and ML
    </Flex>
  )

  return (
    <GlassCard header='Data Sources' level={2} expandable={true} headerButtons={poweredByAI}>
      {expandedContent}
    </GlassCard>
  )
}

export default DataSourcesView
