import { TextField } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { NumericFormat } from 'react-number-format'

interface PercentInputProps {
  label: string
  value: number
  onValueChange: (value: number) => void
  variation?: 'quiet'
}

const PercentInput: FunctionComponent<PercentInputProps> = ({ label, value, onValueChange, variation }) => {
  return (
    <NumericFormat
      label={label}
      variation={variation}
      type='text'
      inputMode='numeric'
      pattern='\d*'
      size='small'
      minWidth='10rem'
      decimalScale={2}
      fixedDecimalScale
      suffix='%'
      customInput={TextField}
      value={value * 100}
      valueIsNumericString={true}
      onValueChange={(v) => onValueChange(Number(v.value) / 100)}
    />
  )
}

export default PercentInput
