import { Loader } from '@aws-amplify/ui-react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { CompanyInfoV3 } from '../API'

export const DELIM = '|-*-*-|'

export function defaultRetry(failureCount, error) {
  const err = error as any
  if (err.status === 404) return false
  if (err.status === 401) return false
  return failureCount < 3
}
export function parseRestError(err: any, defaultMsg?: string) {
  return err.response?.data?.error || err.message || err || defaultMsg || 'unknown error'
}
export function convertToHtml(str: string | undefined | null) {
  if (!str) return <Loader />
  str = str.replace(/<br>/g, ' ')
  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{str}</ReactMarkdown>
}

export function removeDuplicateValues(arr: string[]) {
  return Array.from(new Set(arr))
}

export function isDateValid(d: Date) {
  return !isNaN(d.getTime())
}

export function removeDupByKey<T>(arr: T[], key: string) {
  const out: T[] = []
  const test = {}
  if (!arr) return []
  if (!key) throw new Error('key is required for removeDupByKey')
  for (const a of arr) {
    if (!a[key]) continue
    if (!test[a[key]]) {
      test[a[key]] = true
      out.push(a)
    }
  }

  return out
}

export const getMonthsBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  const startYear = start.getFullYear()
  const startMonth = start.getMonth()
  const endYear = end.getFullYear()
  const endMonth = end.getMonth()

  const months = (endYear - startYear) * 12 + (endMonth - startMonth)

  return months
}

export type O = Record<string, number | string>
export function filterObjectByKeys(obj: O, keysToKeep: string[]): O {
  const filteredObj: O = {}

  for (const key of keysToKeep) {
    if (obj.hasOwnProperty(key)) {
      filteredObj[key] = obj[key]
    }
  }

  return filteredObj
}

export type StatusResponse = {
  status: string
}

export function int(num: string | number): number {
  if (typeof num === 'string') {
    return parseInt(num) || 0
  }
  return num
}

export function str(num: string | number): string {
  if (typeof num !== 'string') {
    return `${num}`
  }
  return num
}

export function date(d: string | number): string {
  if (typeof d === 'number') {
    console.error(`found number instead of date ${d}`)
  }
  return new Date(d).toISOString().split('T')[0]
}

export function median(arr: number[]): number {
  const mid = Math.floor(arr.length / 2)
  const nums = [...arr].sort((a, b) => a - b)
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
}

export function mean(arr: number[]): number {
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

export function pFmt(num: number) {
  return num.toLocaleString('en-us', { style: 'percent', maximumFractionDigits: 2 })
}

export function parseDecimal(num: string | number) {
  return parseFloat(`${num}`.replace(/[^0-9.]/g, ''))
}

export function parseCurrency(num: string | number) {
  return parseFloat(`${num}`.replace(/[$,]/g, ''))
}

export function fDate(d: Date | string): string {
  if (!d) return ''
  if (typeof d === 'string') return date(d)
  return d.toISOString().split('T')[0]
}

export function dateFormatterShort(value: any) {
  if (!value) return '-'
  return new Date(value).toLocaleDateString('en-us', { month: 'short', year: 'numeric' })
}

export function dateFormatterLong(value: any) {
  if (!value) return '-'
  return new Date(value).toLocaleDateString('en-us', { day: 'numeric', month: 'numeric', year: '2-digit' })
}

export const longCurrencyFormat = new Intl.NumberFormat('en-US', {
  notation: 'standard',
  compactDisplay: 'short',
  style: 'currency',
  currency: 'USD',
})

export function currencyFormatterLong(value: any) {
  if (!value) return '-'
  return longCurrencyFormat.format(value)
}

export const shortCurrencyFormat = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  style: 'currency',
  currency: 'USD',
})

export function currencyFormatterShort(value: any) {
  if (!value) return '-'
  return shortCurrencyFormat.format(value)
}

export const multipleFormat = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  style: 'decimal',
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
})

export function multipleFormatter(value: number) {
  return multipleFormat.format(value) + 'x'
}

export const percentFormat = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  style: 'percent',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

export function percentFormatter(value: number) {
  if (typeof value !== 'number') return 'N/A'
  return percentFormat.format(value)
}

export const numberFormat = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  style: 'decimal',
})

export function numberFormatter(value: any) {
  if (!value) return '-'
  return numberFormat.format(value)
}

export function uniqValue(value, existingValues) {
  let count = 0
  while (count <= existingValues.length) {
    const newValue = `${value} ${count > 0 ? count : ''}`.trim()
    if (!existingValues.includes(newValue)) {
      return newValue
    }
    count++
  }

  return `${value} ${count}`
}

export default function debounce(fn, delay = 250) {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export function numberParser(input: string) {
  const n = input.toLowerCase()
  const out = parseFloat(n.replace(/[^0-9.]/g, ''))
  if (isNaN(out)) return NaN
  if (n.includes('trillion')) return out * 1000000000000
  if (n.includes('billion')) return out * 1000000000
  if (n.includes('million')) return out * 1000000
  if (n.includes('thousand')) return out * 1000
  if (n.includes('t')) return out * 1000000000000
  if (n.includes('b')) return out * 1000000000
  if (n.includes('m')) return out * 1000000
  if (n.includes('k')) return out * 1000
  if (n.includes('%')) return out / 100
  if (n.includes('percent')) return out / 100

  return out
}

export function taxonomiesFromInfo(info?: CompanyInfoV3) {
  if (!info) return []
  if (!info.products) return []
  return removeDuplicateValues(info.products.map((p) => p.taxonomiesV2 || []).flat())
}

export function m(num: number | undefined) {
  if (typeof num !== 'number') return 'N/A'
  if (num === Infinity || num === 0) return 'N/M'
  if (num < 0) return 'N/M'
  return multipleFormatter(num)
}

export function p(n: number) {
  if (typeof n !== 'number') return 'N/A'
  if (n === Infinity || n === 0) return 'N/M'
  return percentFormatter(n)
}

export function c(n: number | undefined) {
  if (typeof n !== 'number') return 'N/A'
  if (n === Infinity || n === 0) return 'N/M'
  return currencyFormatterShort(n)
}

export function cleanObj(obj: object) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  })
}

export function removeNonGraphqlKeys<T>(obj: T): Omit<T, '__typename' | '_lastChangedAt' | 'createdAt' | 'updatedAt'> {
  if (Array.isArray(obj)) {
    // @ts-ignore
    return obj.map(removeNonGraphqlKeys)
  } else if (obj !== null && typeof obj === 'object') {
    cleanObj(obj)
    const newObj = {}
    for (const [key, value] of Object.entries(obj)) {
      if (!['__typename', '_lastChangedAt', 'createdAt', 'updatedAt'].includes(key)) {
        newObj[key] = removeNonGraphqlKeys(value)
      }
    }
    // @ts-ignore
    return newObj
  } else {
    return obj
  }
}

export function alphaCompare(s1, s2) {
  const a = s1.toLowerCase().replace(/[^a-z]/g, '')
  const b = s2.toLowerCase().replace(/[^a-z]/g, '')
  return a === b
}
