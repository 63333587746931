import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { FundingRounds } from '../types'

type FinancialsRes = {
  financials: FundingRounds[]
}

const MAX_RETRIES = 3
export function useAllFinancials(enabled: boolean = true): [FundingRounds[] | undefined, boolean, Error | undefined] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['financials'],
    enabled,
    queryFn: queryAllFinancials(),
    retry: (failureCount, error) => {
      if (failureCount > MAX_RETRIES) {
        return false
      }

      return true
    },
  })

  return [data?.financials, isLoading, error as Error | undefined]
}

export function queryAllFinancials() {
  return async () =>
    restAPIRequest<FinancialsRes>({
      path: `deals/financials`,
      method: 'get',
    })
}
