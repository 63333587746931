import './styles/index.css'

import { ThemeProvider } from '@aws-amplify/ui-react'
import { init } from '@fullstory/browser'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import ReactDOM from 'react-dom/client'
import AppSelect from './components/AppSelect'
import { studioTheme } from './ui-components'

init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || 'dev' })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient()

console.log('AWS_COMMIT_ID', process.env.REACT_APP_AWS_COMMIT_ID)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={studioTheme}>
      <QueryClientProvider client={queryClient}>
        <AppSelect />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
)
