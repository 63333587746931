import { Button, Card, Flex, Link, Loader, Text, View } from '@aws-amplify/ui-react'
import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useOpportunity } from '../contexts/opportunityContext'
import { Opportunity } from '../models'
import { loadDoc } from '../services/docs'
import { uploadFileForOpportunity } from '../services/fileStorage'
import { FiUpload, UploadIcon } from '../ui-components'
import { fDate } from '../utils/utils'

interface FileUploaderProps {
  onUploadSuccess?: (s3Key: string, file: File) => Promise<void>
  dropzoneEnabled?: boolean
  tags?: string[]
  uploadButtonText?: string
  [x: string]: any
}

const FileUploader: FunctionComponent<FileUploaderProps> = ({ onUploadSuccess = () => {}, dropzoneEnabled, tags = [], uploadButtonText = 'Upload', vectorize = true, ...rest }) => {
  const opportunity = useOpportunity()
  const [uploading, setUploading] = useState(false)

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles?.[0]
      if (!file) return console.error('no file')
      if (!opportunity) return console.error('no opportunity')

      try {
        setUploading(true)
        const [key, info] = await Promise.all([uploadFileForOpportunity(opportunity, file), loadDoc(file, opportunity.name)])
        await DataStore.save(
          Opportunity.copyOf(opportunity, (updated) => {
            Object.assign(updated, {
              files: [
                ...(updated.files || []).filter((f) => f.s3Key !== key),
                {
                  s3Key: key,
                  name: info.documentName,
                  dateUploaded: fDate(new Date()),
                  docType: info.documentType || 'Unknown',
                  vectorDbId: info.documentId,
                  tags,
                },
              ],
            })
          })
        )
        await onUploadSuccess(key, file)
      } catch (error) {
        alert(`Error uploading file. Please try again later`)
        console.error('Error uploading file', error)
      } finally {
        setUploading(false)
      }
    },
    [onUploadSuccess, opportunity, tags]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: uploading,
    accept: {
      'text/*': [],
      'application/pdf': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
  })

  if (!opportunity) return null

  const notUploaded = (
    <>
      <UploadIcon />
      <Text>
        <Link>Click to upload</Link> or drag and drop file
      </Text>
      <Text>PDF, TXT, TEXT, LOG, MD, DOCX, or PPTX</Text>
    </>
  )

  const uploadingMsg = (
    <>
      <Text>Uploading...</Text>
      <Loader variation='linear' />
    </>
  )
  const dropzone = (
    <Card>
      <Flex direction='column' justifyContent={'center'} alignItems={'center'}>
        {uploading ? uploadingMsg : notUploaded}
      </Flex>
    </Card>
  )
  const button = (
    <Button isFullWidth isLoading={uploading}>
      <FiUpload width='1rem' className='documentUploadButton' /> {uploadButtonText}
    </Button>
  )
  return (
    <View {...getRootProps()} {...rest}>
      {dropzoneEnabled ? dropzone : button}
      <input {...getInputProps()} accept='.pdf,.txt,.text,text/*,.docx,.pptx,.csv,.xlsx,.xls' />
    </View>
  )
}

export default FileUploader
