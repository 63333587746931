import { Button, Flex, Heading, Image, Loader, SelectField, Text, TextField } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import ReactModal from 'react-modal'
import { useLocation, useNavigate } from 'react-router-dom'
import WebsiteChat from '../components/WebsiteChat'
import { useOpportunity } from '../contexts/opportunityContext'
import { getGroupNames, useSetUser, useUser } from '../contexts/userContext'
import { DEAL, DOC_AI, TARGET_PUBLIC_BENCHMARK, TRANSACTION_MODEL, VALUATION, getPathTo } from '../sitemap'
import '../styles/model.scss'
import { BsBookmark, CloseIcon, CompanyIcon, DeleteIcon, FilesIcon, MetricsBenchmarkingIcon, TransactionModelIcon, ValuationCompsIcon } from '../ui-components'
import GlassCard from './GlassCard'

interface CompanyHeroProps {
  iconUrl?: string | null
  companyName: string
  companyLabel: string
  description?: string | null
  onCreate: (transactionName: string) => Promise<void>
  onDelete: () => Promise<void>
  isPublic: boolean
  websiteSources?: string[]
}

const CompanyHeroView: FunctionComponent<CompanyHeroProps> = ({ iconUrl, companyName, companyLabel, description, onCreate, onDelete, isPublic, websiteSources = [] }) => {
  const user = useUser()
  const setUser = useSetUser()
  const groups = user?.groups ?? []
  const groupNames = getGroupNames(user)
  const [transactionName, setTransactionName] = useState<string>('')
  const [showNewDealModal, setShowNewDealModal] = useState(false)
  const [isCreatingNewDeal, setIsCreatingNewDeal] = useState(false)
  const [groupName, setGroupName] = useState<string>(groupNames[0])
  const [showSaveListModal, setShowSaveListModal] = useState(false)
  const [isSavingNewList, setIsSavingNewList] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const opportunity = useOpportunity()
  if (!companyLabel) return <Loader />
  const companyLogoImg = iconUrl ? <Image alt='company image' src={iconUrl} maxHeight={80} borderRadius='0.5rem' /> : null

  const buttons = isPublic ? (
    <Flex>
      <Button onClick={() => setShowSaveListModal(true)} size='small'>
        <BsBookmark marginRight='0.5rem' /> Save to List
      </Button>
      <Button onClick={() => setShowNewDealModal(true)} size='small' variation='primary'>
        <ValuationCompsIcon marginRight='0.5rem' /> Start Deal
      </Button>
    </Flex>
  ) : (
    <>
      {/* <Button
        size='small'
        className={`tooltip down ${location.pathname.includes('profile') ? 'active' : ''}`}
        data-tooltip='Profile'
        onClick={() => navigate(getPathTo(DEAL, { opportunityId: opportunity?.id }))}
      >
        <CompanyIcon />
      </Button>
      <Button size='small' className='tooltip down' data-tooltip='Financials' onClick={() => navigate(getPathTo(TARGET_PUBLIC_BENCHMARK, { opportunityId: opportunity?.id }))}>
        <TransactionModelIcon />
      </Button>
      <Button size='small' className='tooltip down' data-tooltip='Valuation' onClick={() => navigate(getPathTo(VALUATION, { opportunityId: opportunity?.id }))}>
        <MetricsBenchmarkingIcon />
      </Button>
      <Button size='small' className='tooltip down' data-tooltip='Transaction Model' onClick={() => navigate(getPathTo(TRANSACTION_MODEL, { opportunityId: opportunity?.id }))}>
        <ValuationCompsIcon />
      </Button>
      <Button size='small' className='tooltip down' data-tooltip='Document AI' onClick={() => navigate(getPathTo(DOC_AI, { opportunityId: opportunity?.id }))}>
        <FilesIcon />
      </Button> */}
      <Button variation='destructive' size='small' onClick={() => onDelete()}>
        Delete Deal
      </Button>
    </>
  )

  return (
    <GlassCard header=''>
      <Flex direction='row' justifyContent='space-between'>
        <Flex direction='row' alignItems='center'>
          {companyLogoImg}
          <Flex direction='column' gap='0.5rem'>
            <Flex alignItems='center'>
              <Heading level={3} fontSize='2rem' fontWeight='700' marginRight='3rem'>
                {companyLabel} {!isPublic && `- ${opportunity?.transactionName}`}
              </Heading>
            </Flex>
            <Text>{description}</Text>
          </Flex>
        </Flex>
        <Flex direction='row' height='fit-content'>
          {buttons}
        </Flex>
      </Flex>
      <Flex direction='column'>
        <WebsiteChat targetCompany={companyName} websiteSources={websiteSources} />
      </Flex>
      <ReactModal appElement={document.getElementById('app')} isOpen={showNewDealModal} onRequestClose={() => setShowNewDealModal(false)}>
        <GlassCard header='Create New Deal' className='modal' headerButtons={<CloseIcon width='0.75rem' className='actionButton' onClick={() => setShowNewDealModal(false)} />}>
          <Flex direction='column'>
            <TextField
              label='Deal Name'
              placeholder='Enter deal name'
              isRequired={true}
              size='small'
              value={transactionName}
              autoComplete='off'
              autoCorrect='off'
              autoCapitalize='off'
              spellCheck={false}
              autoFocus
              onChange={(e) => {
                setTransactionName(e.target.value)
              }}
            />
          </Flex>
          <Flex justifyContent='flex-end'>
            <Button onClick={() => setShowNewDealModal(false)} variation='link' size='small'>
              Cancel
            </Button>
            <Button
              isLoading={isCreatingNewDeal}
              loadingText='Creating Deal...'
              variation='primary'
              size='small'
              onClick={() => {
                if (isCreatingNewDeal) return
                setIsCreatingNewDeal(true)
                onCreate(transactionName)
                  .catch((e) => console.error('onCreate error', e))
                  .finally(() => {
                    setIsCreatingNewDeal(false)
                    setShowNewDealModal(false)
                  })
              }}
            >
              Create Deal
            </Button>
          </Flex>
        </GlassCard>
      </ReactModal>
      <ReactModal appElement={document.getElementById('app')} isOpen={showSaveListModal} onRequestClose={() => setShowSaveListModal(false)}>
        <GlassCard header='Save to List' className='modal' headerButtons={<CloseIcon width='0.75rem' className='actionButton' onClick={() => setShowSaveListModal(false)} />}>
          <Flex direction='column'>
            <SelectField
              flex={1}
              placeholder='Select saved list'
              size='small'
              label={'Saved Lists'}
              options={groupNames}
              value={groupName}
              onChange={(e) => {
                setGroupName(e.target.value)
              }}
            />
          </Flex>
          <Flex justifyContent='flex-end'>
            <Button onClick={() => setShowSaveListModal(false)} variation='link' size='small'>
              Cancel
            </Button>
            <Button
              isLoading={isSavingNewList}
              loadingText='Saving...'
              variation='primary'
              size='small'
              onClick={async () => {
                setIsSavingNewList(true)
                await setUser({
                  groups: [
                    {
                      name: companyName,
                      groupName,
                    },
                    ...groups.filter((g) => {
                      if (g.groupName === groupName && g.name === companyName) return false
                      return true
                    }),
                  ],
                })
                  .catch((e) => console.error('setUser error', e))
                  .finally(() => {
                    setIsSavingNewList(false)
                    setShowSaveListModal(false)
                  })
              }}
            >
              Save to List
            </Button>
          </Flex>
        </GlassCard>
      </ReactModal>
    </GlassCard>
  )
}

export default CompanyHeroView
