import { GraphQLQuery } from '@aws-amplify/api'
import { graphqlOperation } from '@aws-amplify/api-graphql'
import { DataStore } from '@aws-amplify/datastore'
import { generateClient } from 'aws-amplify/api'
import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateOpportunityInput, CreateOpportunityMutation } from '../API'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { useOpportunity, useSetOpportunity } from '../contexts/opportunityContext'
import { useUser } from '../contexts/userContext'
import CompanyHeroView from '../figma-components/CompanyHeroView'
import { createOpportunity } from '../graphql/mutations'
import { CompanyGroups, Opportunity, User } from '../models'
import { restAPIRequest } from '../services/restApiRequest'
import { sendSlack } from '../services/slack'
import { DEAL, DEALS_LIST, getPathTo } from '../sitemap'
import { parseRestError, removeNonGraphqlKeys } from '../utils/utils'

const API = generateClient()

interface CompanyHeroProps {
  iconUrl?: string | null
  companyName: string
  companyLabel: string
  description: string
  isPublic: boolean
  publicComps: CompanyGroups[]
  marketComps: string[]
  websiteSources?: string[]
}

const CompanyHero: FunctionComponent<CompanyHeroProps> = ({ iconUrl, companyName, companyLabel, description, isPublic, publicComps, marketComps, websiteSources = [] }) => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const setOpp = useSetOpportunity()
  const user = useUser()
  const userAmplify = useAmplifyUser()
  const navigate = useNavigate()
  const opportunity = useOpportunity()
  const orgName = userAmplify?.org || 'noOrg'

  async function submit(transactionName: string) {
    if (isSending) return
    if (!transactionName) return alert('Please enter a Opportunity Name')
    setIsSending(true)

    try {
      const update: CreateOpportunityInput = {
        name: companyName,
        orgName,
        transactionName,
        publicCompsCompSetV2: publicComps,
        marketMapCompSet: marketComps,
        financialData: {},
      }
      const input = removeNonGraphqlKeys(update)
      const res = await API.graphql<GraphQLQuery<CreateOpportunityMutation>>(graphqlOperation(createOpportunity, { input }))
      const opp = res.data?.createOpportunity
      if (!opp) throw new Error('no opp returned from createOpportunity')
      if (!['demo', 'admin', 'kelley_yu', 'teamone', 'teamtwo', 'test_new_group', ''].includes(orgName.toLowerCase())) {
        sendSlack(`Opportunity Created by ${userAmplify?.email}: ${companyName}`)
      }
      await restAPIRequest({
        method: 'post',
        path: 'opportunity',
        body: {
          companyName,
          companyLabel,
          transactionName,
          dealId: opp.id,
        },
      })
      setOpp(opp.id)
      navigate(getPathTo(DEAL, { opportunityId: opp.id }))
    } catch (qlErr: any) {
      console.error('error creating deal in graphql', qlErr)
      alert('We had an issue creating this deal. Our team is looking into this. Please try again later.')
      sendSlack(`Error in graphql api creating oppy: ${qlErr.message}. info ${JSON.stringify(qlErr)}`)
    } finally {
      setIsSending(false)
    }
  }

  async function onDelete(): Promise<void> {
    if (!opportunity) return console.error('no opportunity to delete')
    try {
      await restAPIRequest({
        path: `opportunity/${opportunity.id}`,
        body: {
          tableauProjectId: '',
          googleSheetIds: [],
        },
        method: 'del',
      })
      const modelToDelete = await DataStore.query(Opportunity, opportunity.id)
      if (!modelToDelete) {
        throw new Error(`could not find model to delete ${opportunity.id}`)
      }
      if (!user) return console.error('no user when deleting opp')
      await DataStore.save(
        User.copyOf(user, (updated) => {
          Object.assign(updated, {
            websiteChatsV2: updated?.websiteChatsV2?.filter((c) => c.target !== opportunity?.id) || [],
          })
        })
      )
      await DataStore.delete(modelToDelete)
      navigate(getPathTo(DEALS_LIST))
    } catch (e) {
      console.error('error deleting opp', parseRestError(e))
    }
  }

  return (
    <CompanyHeroView
      iconUrl={iconUrl}
      companyName={companyName}
      companyLabel={companyLabel}
      description={description}
      onCreate={submit}
      onDelete={onDelete}
      isPublic={isPublic}
      websiteSources={websiteSources}
    />
  )
}

export default CompanyHero
