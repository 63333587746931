import { Flex, Icon, Image, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OptionProps, components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { useCyber } from '../contexts/cyberContext'
import { listCompanies } from '../services/crunchbase'
import { FULL_CRUNCHBASE, enabledForOrg } from '../services/featureFlags'
import { COMPANY_PROFILES, getPathTo } from '../sitemap'
import { AutocompleteOption, SAFE_INPUT_CLASS } from '../types'

interface ProfileSearchProps {
  setSelected?: (selected: AutocompleteOption | undefined) => void
  disable?: boolean
  enableNavigate?: boolean
  placeholder?: string
}

const ProfileSearch: FunctionComponent<ProfileSearchProps> = ({ placeholder, enableNavigate, disable, setSelected = () => {} }) => {
  const [selected, setSelectedInternal] = useState<AutocompleteOption>()
  const [cyber, loadingCyber] = useCyber()
  const targetNames = Object.keys(cyber).sort()
  const navigate = useNavigate()
  const amplifyUser = useAmplifyUser()
  const orgGroups = amplifyUser?.groups

  const targetOptions: AutocompleteOption[] = targetNames.map((i: string) => {
    return { id: cyber[i].companyName, label: cyber[i].companyLabel, img: cyber[i]?.img, shortDescription: cyber[i]?.shortDescription }
  })

  const Option = (props: OptionProps<AutocompleteOption>) => {
    const { label, img, shortDescription } = props.data
    return (
      // @ts-ignore
      <components.Option {...props}>
        <Flex gap='.5rem'>
          {img ? <Image src={img} alt={label} width='25px' height='25px' /> : <Icon width={25} height={25}></Icon>}
          <Text whiteSpace='nowrap'>{label}</Text>
          <Text color={'gray'} isTruncated>
            {(shortDescription || '').replace(label, '')}
          </Text>
        </Flex>
      </components.Option>
    )
  }

  const getOptions = async (inputValue: string) => {
    const startsWith: AutocompleteOption[] = []
    const hasInside: AutocompleteOption[] = []
    targetOptions.forEach((o) => {
      if (search(o, inputValue)) {
        if (o.label.toLowerCase().startsWith(inputValue.toLowerCase())) {
          startsWith.push(o)
        } else {
          hasInside.push(o)
        }
      }
    })
    const filteredOptions = startsWith.concat(hasInside)

    // TODO: remove when we are ready for all options
    if (!enabledForOrg(orgGroups, FULL_CRUNCHBASE) || filteredOptions.length > 8) return filteredOptions.slice(0, 100)
    try {
      const options = await listCompanies(inputValue)
      return filteredOptions.concat(options.filter((o) => !targetNames.find((t) => cyber[t].companyName === o.id)))
    } catch (e) {
      console.error('error filtering options', e)
      return filteredOptions.slice(0, 100)
    }
  }

  const onChange = async (inputValue) => {
    if (!inputValue) {
      setSelectedInternal(undefined)
      setSelected(undefined)
      return
    }
    setSelectedInternal(inputValue)
    setSelected(inputValue)

    if (enableNavigate)
      navigate(
        getPathTo(COMPANY_PROFILES, {
          targetCompany: inputValue.id,
        })
      )
  }

  return (
    // <AsyncCreatableSelect
    <Flex>
      <AsyncSelect
        className={SAFE_INPUT_CLASS}
        placeholder={placeholder || 'Search...'}
        openMenuOnClick={true}
        escapeClearsValue={false}
        isDisabled={disable}
        isLoading={loadingCyber}
        components={{ Option }}
        isClearable
        noOptionsMessage={({ inputValue }) => {
          if (inputValue === '') {
            return 'Type to search for companies'
          }
          return 'No matching companies found'
        }}
        cacheOptions
        value={selected}
        loadOptions={getOptions}
        // formatCreateLabel={(inputValue) => `Request ${inputValue}`}
        onChange={onChange}
      />
    </Flex>
  )
}

function search(o, name) {
  return (
    o?.label
      ?.toLowerCase?.()
      ?.trim()
      ?.replace(/[^a-z0-9]/g, '')
      ?.includes?.(
        name
          ?.toLowerCase()
          ?.trim()
          ?.replace(/[^a-z0-9]/g, '')
      ) ||
    name
      .toLowerCase?.()
      ?.trim()
      ?.replace(/[^a-z0-9]/g, '')
      ?.includes?.(
        o?.label
          ?.toLowerCase()
          ?.trim()
          ?.replace(/[^a-z0-9]/g, '')
      )
  )
}

export default ProfileSearch
