// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Opportunity, User, Org, CompanyInfoV3, MarketInfoV1, Dashboards, Tasks, File, DocAI, DocAIMessage, AIMessageV2, DocChat, WebsiteChat, CompanyGroups, ListInfo, ListInfoData, OrgDashboards, FinancialDataV2, FinancialData, YearlyFinancialData, PublicFinancialData, SourceTypes, Overrides, ProductInfo } = initSchema(schema);

export {
  Opportunity,
  User,
  Org,
  CompanyInfoV3,
  MarketInfoV1,
  Dashboards,
  Tasks,
  File,
  DocAI,
  DocAIMessage,
  AIMessageV2,
  DocChat,
  WebsiteChat,
  CompanyGroups,
  ListInfo,
  ListInfoData,
  OrgDashboards,
  FinancialDataV2,
  FinancialData,
  YearlyFinancialData,
  PublicFinancialData,
  SourceTypes,
  Overrides,
  ProductInfo
};