import { FunctionComponent, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'

type ChartData = {
  value: number
  name: string
  group: string
}

type TransactionModelChart1PieChartParams = {
  data: ChartData[]
}

class Color {
  saturation: number

  constructor(
    public hue: number,
    public lightness: number
  ) {
    this.hue = Math.round(hue)
    this.saturation = 80
    this.lightness = lightness
  }

  toString() {
    return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`
  }

  setLightness(lightness: number) {
    return new Color(this.hue, lightness)
  }

  static fromHSLString(hslString: string) {
    const hslRegex = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g
    const match = hslRegex.exec(hslString)
    if (match) {
      const hue = parseInt(match[1])
      // const saturation = parseInt(match[2])
      const lightness = parseInt(match[3])
      return new Color(hue, lightness)
    }
    throw new Error(`Invalid HSL string ${hslString}`)
  }
}

function generateColors(amount: number) {
  const colors: Color[] = []

  const degrees = 360

  for (let i = 0; i < amount; i++) {
    const hue = degrees * (i / amount)

    const color = new Color(hue, 65)

    colors.push(color)
  }

  // Return the array of colors.
  return colors
}

function generateHues(base: Color, amount: number) {
  const colors: Color[] = []

  const minBrightness = 50
  const maxBrightness = 80
  const step = Math.min((maxBrightness - minBrightness) / amount, 10)

  for (let i = 0; i < amount; i++) {
    const brightness = Math.round(minBrightness + i * step)
    const color = base.setLightness(brightness)
    colors.push(color)
  }

  // Return the array of colors.
  return colors
}

const TransactionModelChart1PieChart: FunctionComponent<TransactionModelChart1PieChartParams> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const sortedData = data.sort((a, b) => a.group.toLowerCase().localeCompare(b.group.toLowerCase()))
  const groups: Record<string, ChartData[]> = {}
  data.forEach((item) => {
    if (!groups[item.group]) {
      groups[item.group] = []
    }
    groups[item.group].push(item)
  })

  const colors: Record<string, Record<string, Color>> = {}

  const groupColors = generateColors(Object.keys(groups).length)
  Object.keys(groups).forEach((group, index) => {
    colors[group] = {}
    const hues = generateHues(groupColors[index], groups[group].length)
    groups[group].forEach((item, index) => {
      colors[group][item.name] = hues[index]
    })
  })

  const renderActiveShape = (props: {
    cx: number
    cy: number
    midAngle: number
    innerRadius: number
    outerRadius: number
    startAngle: number
    endAngle: number
    fill: string
    payload: any
    percent: number
    value: any
  }) => {
    // const RADIAN = Math.PI / 180
    const {
      cx,
      cy,
      // midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      // value,
    } = props
    // const sin = Math.sin(-RADIAN * midAngle)
    // const cos = Math.cos(-RADIAN * midAngle)
    // const sx = cx + (outerRadius + 10) * cos
    // const sy = cy + (outerRadius + 10) * sin
    // const mx = cx + (outerRadius + 30) * cos
    // const my = cy + (outerRadius + 30) * sin
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22
    // const ey = my
    // const textAnchor = cos >= 0 ? 'start' : 'end'
    const baseColor = Color.fromHSLString(fill)
    const darkColor = baseColor.setLightness(60).toString()
    return (
      <g>
        {/* Inner ring */}
        <Sector cx={cx} cy={cy} innerRadius={innerRadius + 3} outerRadius={outerRadius + 3} startAngle={startAngle} endAngle={endAngle} fill={baseColor.toString()} />
        {/* Select outter ring */}
        <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={darkColor} />
        {/* Name of shareholder */}
        <text x={cx} y={cy} dy={'-50%'} textAnchor='middle' fill={darkColor} fontSize={`1.2rem`}>
          {payload.name}
        </text>
        {/* Name of group */}
        <text x={cx} y={cy} dy={'-43%'} textAnchor='middle' fill={darkColor} fontSize={`1rem`}>
          {payload.group} ({`${(percent * 100).toFixed(2)}%`})
        </text>
      </g>
    )
  }
  return (
    <ResponsiveContainer height='100%' width='100%'>
      <PieChart height={250} width={250}>
        <Pie
          activeIndex={activeIndex}
          // @ts-ignore
          activeShape={renderActiveShape}
          data={sortedData}
          cx='50%'
          cy='60%'
          innerRadius={60}
          outerRadius={110}
          dataKey='value'
          onMouseEnter={(_, index) => {
            setActiveIndex(index)
          }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[entry.group][entry.name].toString()} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
export default TransactionModelChart1PieChart
