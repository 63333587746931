import { Flex, Image, Link, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import Charts, { ChartData } from '../components/Charts'
import '../styles/companyOverview.css'
import { currencyFormatterShort, dateFormatterShort, percentFormatter } from '../utils/utils'
import FundingRoundsTable from './FundingRounds'
import GlassCard from './GlassCard'
import RelationshipsView from './RelationshipsView'
import TieringView from './TieringView'
import FinancialsTable from './FinancialsTable'
import { FundingRounds } from '../types'

interface CompanyOverviewProps {
  companyName: string
  companyLabel: string
  cb_foundedDate?: string | null
  // companyType?: string | null
  // cb_acquisitionStatus?: string | null
  acquiredBy?: string | null
  cb_website?: string | null
  linkedInWebsite?: string | null
  headquartersLocation?: string | null
  headquartersRegion?: string | null
  ltmRevenue?: string | number | null
  totalEquityRaised?: string | number | null
  fullTimeEmployeeCount?: number | null
  fullTimeEmployeeCountGrowth6m?: number | null
  fullTimeEmployeeCountGrowth1y?: number | null
  fullTimeEmployeeCountGrowth2y?: number | null
  // lastPreMoneyValuation?: string | number | null
  // lastPostMoneyValuation?: string | number | null
  monthsSinceLastDeal?: string | number | null
  acquisitionStatus?: string | number | null
  keyInvestors?: string | null
  raiseData: ChartData[]
  financials?: FundingRounds[]
}

const CompanyOverviewFigma: FunctionComponent<CompanyOverviewProps> = ({
  companyName,
  companyLabel,
  cb_foundedDate,
  // companyType,
  // cb_acquisitionStatus,
  acquiredBy,
  cb_website,
  linkedInWebsite,
  headquartersLocation,
  headquartersRegion,
  ltmRevenue,
  totalEquityRaised,
  fullTimeEmployeeCount,
  fullTimeEmployeeCountGrowth6m,
  fullTimeEmployeeCountGrowth1y,
  fullTimeEmployeeCountGrowth2y,
  // lastPreMoneyValuation,
  // lastPostMoneyValuation,
  monthsSinceLastDeal,
  acquisitionStatus,
  keyInvestors,
  raiseData,
  financials,
}) => {
  const location = headquartersRegion || headquartersLocation || 'N/A'

  const headerValue = (header, value, tooltip = header) => (
    <Flex>
      <Text className='header6 tooltip' data-tooltip={tooltip}>
        {header}
      </Text>
      <Text width='60%'>{value || 'N/A'}</Text>
    </Flex>
  )

  const crunchbaseSource = (
    <Flex alignItems='center' marginLeft='auto' gap='0.5rem'>
      <Text fontSize='12px'>powered by</Text>
      <Image src='/images/CrunchbaseLogo.png' alt='crunchbase-logo' width='25px' />
    </Flex>
  )

  const financialOverviewCard = (
    <GlassCard header='Financial Overview' className='overlayCard'>
      {headerValue('LTM Revenue', typeof ltmRevenue === 'string' ? ltmRevenue : currencyFormatterShort(ltmRevenue), 'Revenue generated by the company in the last twelve months')}
      {headerValue('Total Equity $ Raised', currencyFormatterShort(totalEquityRaised), 'Total amount of money raised across all funding rounds')}
      {/* {headerValue('Last Pre-Money Valuation', currencyFormatterShort(lastPreMoneyValuation))} */}
      {/* {headerValue('Last Post-Money Valuation', currencyFormatterShort(lastPostMoneyValuation))} */}
      {headerValue('Months Since Last Raise', monthsSinceLastDeal, 'Number of months since the last funding round')}
      {(acquiredBy !== 'N/A' || acquisitionStatus !== 'N/A') && (
        <Flex>
          <Text className='header6 tooltip' data-tooltip='Current status of whether or not the company was acquired'>
            Acquisition Status
          </Text>
          <Flex direction='column' gap='0px'>
            <Text>Acquired By: {acquiredBy || 'N/A'}</Text>
            <Text>Status: {acquisitionStatus || 'N/A'}</Text>
          </Flex>
        </Flex>
      )}
      {headerValue('Key Investors', keyInvestors, 'Name of the investor(s) who led the investment in the funding round, across all funding rounds')}
      {crunchbaseSource}
    </GlassCard>
  )

  const companyOverviewCard = (
    <GlassCard header='Company Overview' className='overlayCard'>
      {headerValue('HQ', location || 'N/A', 'Headquarters location')}
      {headerValue('Employees', fullTimeEmployeeCount || 'N/A', 'Number of employees')}
      {typeof fullTimeEmployeeCountGrowth6m === 'number' &&
        headerValue('FTE 6m Growth', percentFormatter(fullTimeEmployeeCountGrowth6m) || 'N/A', 'Growth rate of employees over 6 months')}
      {typeof fullTimeEmployeeCountGrowth1y === 'number' &&
        headerValue('FTE 1yr Growth', percentFormatter(fullTimeEmployeeCountGrowth1y) || 'N/A', 'Growth rate of employees over 1 year')}
      {typeof fullTimeEmployeeCountGrowth2y === 'number' &&
        headerValue('FTE 2yr Growth', percentFormatter(fullTimeEmployeeCountGrowth2y) || 'N/A', 'Growth rate of employees over 2 years')}
      {headerValue('Date Founded', dateFormatterShort(cb_foundedDate) || 'N/A', 'Date the company was founded')}
      {/* {headerValue('Entity Type', companyType || 'N/A')} */}
      {/* {headerValue('Financing Status', cb_acquisitionStatus || 'N/A')} */}
      {headerValue('Website', cb_website ? <Link isExternal={true} href={cb_website} children={cb_website} /> : <Text>N/A</Text>, 'Company website')}
      {headerValue(
        'LinkedIn',
        linkedInWebsite ? <Link isExternal={true} href={linkedInWebsite} children={<Image src='/images/LinkedInLogo.svg' alt='linkedin-logo' />} /> : <Text>N/A</Text>,
        'Company LinkedIn'
      )}
      {crunchbaseSource}
    </GlassCard>
  )

  const fundingRounds = financials?.length ? <FinancialsTable financials={financials} /> : <FundingRoundsTable data={raiseData} crunchbaseSource={crunchbaseSource} />

  const charts = <Charts raiseData={raiseData} crunchbaseSource={crunchbaseSource} />

  return (
    <GlassCard header='' className='firstCard'>
      <Flex>
        {companyOverviewCard}
        {financialOverviewCard}
        <RelationshipsView keyInvestors={[companyName, companyLabel, ...(keyInvestors || '').split(',').map((i) => i.trim())]} />
        <TieringView companyName={companyName} />
      </Flex>
      <GlassCard header='Funding Rounds' className='overlayCard' overflow='scroll'>
        {fundingRounds}
      </GlassCard>
      <Flex direction='column'>{charts}</Flex>
    </GlassCard>
  )
}

export default CompanyOverviewFigma
