import { Flex } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { FundingRounds } from '../types'
import CandleStickView, { ChartData } from './CandleStickView'
import InputTableView from './InputTableView'

interface BenchmarkingDashboardProps {
  data: FundingRounds[]
}

function pushIfValid(arr: Record<string, ChartData[]>, info: FundingRounds, name: string) {
  const value = info[name]
  if (typeof value !== 'number') return
  if (value === Infinity) return

  if (value) {
    arr[name].push({ value, name: info.round || 'N/A' })
  }
}
const FundingBenchmarkingDashboards: FunctionComponent<BenchmarkingDashboardProps> = ({ data }) => {
  const chartData = data.reduce(
    (p, c) => {
      pushIfValid(p, c, 'ntmRevGrowth')
      pushIfValid(p, c, 'ltmRevGrowth')
      pushIfValid(p, c, 'ltmARRGrowth')

      pushIfValid(p, c, 'ntmARRGrowth')
      pushIfValid(p, c, 'netRetentionPercent')
      pushIfValid(p, c, 'grossRetentionPercent')

      pushIfValid(p, c, 'snmAsPercentOfRev')
      pushIfValid(p, c, 'salesEfficiencyMultiple')
      pushIfValid(p, c, 'implied5yrLTVPerCAC')

      pushIfValid(p, c, 'grossMarginPercent')
      pushIfValid(p, c, 'eBITDAMarginPercent')
      pushIfValid(p, c, 'ruleOf40LTMEbitda')

      return p
    },
    {
      ntmRevGrowth: [],
      ltmRevGrowth: [],
      ltmARRGrowth: [],
      ntmARRGrowth: [],
      netRetentionPercent: [],
      grossRetentionPercent: [],
      snmAsPercentOfRev: [],
      salesEfficiencyMultiple: [],
      implied5yrLTVPerCAC: [],
      grossMarginPercent: [],
      eBITDAMarginPercent: [],
      ruleOf40LTMEbitda: [],
    } as Record<string, ChartData[]>
  )

  return (
    <Flex direction='row'>
      <Flex direction='column' flex='1'>
        <InputTableView tableTitle='Rev Growth' isVisible={true}>
          <Flex direction='column' minHeight='450px' height='100%' maxHeight='50vh'>
            <Flex direction='row' flex='1' padding='1rem 0'>
              <CandleStickView data={chartData.ntmRevGrowth} label='NTM Rev Growth' valueType='percent' />
              <CandleStickView data={chartData.ltmRevGrowth} label='LTM Rev Growth' valueType='percent' />
              <CandleStickView data={chartData.ltmARRGrowth} label='LTM ARR Growth' valueType='percent' />
            </Flex>
          </Flex>
        </InputTableView>
        <InputTableView tableTitle='Booking & Retention' isVisible={true}>
          <Flex direction='column' minHeight='450px' height='100%' maxHeight='50vh'>
            <Flex direction='row' flex='1' padding='1rem 0'>
              <CandleStickView data={chartData.ntmARRGrowth} label='NTM ARR Growth' valueType='percent' />
              <CandleStickView data={chartData.netRetentionPercent} label='Net $ Retention' valueType='percent' />
              <CandleStickView data={chartData.grossRetentionPercent} label='Gross $ Retention' valueType='percent' />
            </Flex>
          </Flex>
        </InputTableView>
      </Flex>
      <Flex direction='column' flex='1'>
        <InputTableView tableTitle='S&M Efficiency Metrics' isVisible={true}>
          <Flex direction='column' minHeight='450px' height='100%' maxHeight='50vh'>
            <Flex direction='row' flex='1' padding='1rem 0'>
              <CandleStickView data={chartData.snmAsPercentOfRev} label='S&M as % of Rev' valueType='percent' />
              <CandleStickView data={chartData.salesEfficiencyMultiple} label='Sales Efficiency' valueType='multiple' />
              <CandleStickView data={chartData.implied5yrLTVPerCAC} label='LTV / CAC' valueType='multiple' />
            </Flex>
          </Flex>
        </InputTableView>
        <InputTableView tableTitle='Operational Efficiency' isVisible={true}>
          <Flex direction='column' minHeight='450px' height='100%' maxHeight='50vh'>
            <Flex direction='row' flex='1' padding='1rem 0'>
              <CandleStickView data={chartData.grossMarginPercent} label='Gross Margin' valueType='percent' />
              <CandleStickView data={chartData.eBITDAMarginPercent} label='EBITDA Margin' valueType='percent' />
              <CandleStickView data={chartData.ruleOf40LTMEbitda} label='Rule of 40 LTM / EBITDA' valueType='percent' />
            </Flex>
          </Flex>
        </InputTableView>
      </Flex>
    </Flex>
  )
}

export default FundingBenchmarkingDashboards
