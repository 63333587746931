import { Card, Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { CartesianGrid, Customized, Label, Rectangle, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts'
import { currencyFormatterShort, multipleFormatter, percentFormatter } from '../utils/utils'

export type ChartData = {
  name: string
  value: number
}

export type ValueTypes = 'percent' | 'currency' | 'multiple'
interface CandleStickViewProps {
  data: ChartData[]
  target?: ChartData
  label: string
  valueType: ValueTypes
}

const CandleStickView: FunctionComponent<CandleStickViewProps> = ({ data, target, label, valueType }) => {
  const points = data.map((d) => ({ ...d, key: '' }))

  const targetPoint = {
    key: '',
    value: target?.value,
    name: target?.name,
  }
  let formatter = currencyFormatterShort
  if (valueType === 'percent') {
    formatter = percentFormatter
  } else if (valueType === 'multiple') {
    formatter = multipleFormatter
  }
  const hingeCalcs = calcHinge(points)

  return (
    <Flex gap='.1rem' direction='column' width='100%' height='100%' padding='.5rem'>
      <ResponsiveContainer width='100%' height='100%'>
        <ScatterChart>
          <CartesianGrid />
          <XAxis name={label} label={label} dataKey='key' type='category' allowDuplicatedCategory={false} />
          <YAxis name={label} dataKey='value' type='number' allowDecimals interval='preserveStartEnd' tickFormatter={formatter} />
          <Tooltip content={<CustomTooltip info={{ label, formatter }} />} />
          <ReferenceLine y={target?.value} stroke='orange' isFront={true} ifOverflow='extendDomain'>
            <Label value='Target' position='insideTopLeft' color='orange' fill='orange' />
          </ReferenceLine>
          <Customized hingeCalcs={hingeCalcs} component={CustomizedRectangle} />
          <Scatter data={points} fill='#8884d8' />
          <Scatter data={[targetPoint]} fill='orange' />
        </ScatterChart>
      </ResponsiveContainer>
      <Text>Data Points {data.length}</Text>
      <Text># Companies {hingeCalcs.uniqueNamesCount}</Text>
      <Text>Median {formatter(hingeCalcs.median)}</Text>
    </Flex>
  )
}

const CustomTooltip = ({ active, payload, info: { label, formatter } }: any) => {
  if (!active || !payload || !payload.length) {
    return null
  }

  const { value, name } = payload[0].payload

  return (
    <Card variation='elevated' z-index={500}>
      <Text>
        {label}: {formatter(value)}
      </Text>
      {name && <Text>Company Name: {name}</Text>}
    </Card>
  )
}

const CustomizedRectangle = (props) => {
  const { formattedGraphicalItems, hingeCalcs } = props
  const points = formattedGraphicalItems[0].props.points
  if (points.length === 0) {
    return null
  }
  const { median, lowerHinge, upperHinge } = hingeCalcs
  const { lowerWhisker, upperWhisker } = calculateWhiskers(points)
  const ratio = Math.abs((upperWhisker.cy - lowerWhisker.cy) / (upperWhisker.value - lowerWhisker.value))
  const base = lowerWhisker.cy

  const width = 100
  const height = upperWhisker.cy - lowerWhisker.cy
  const x = points[0].cx - width / 2
  return (
    <>
      {/* top line */}
      <Rectangle x={x} y={upperWhisker.cy - 1.5} width={width} height={3} fill='black' stroke='none' />
      {/* bottom line */}
      <Rectangle x={x} y={lowerWhisker.cy - 1.5} width={width} height={3} fill='black' stroke='none' />
      {/* vertical line */}
      <Rectangle x={x + width / 2 - 1.5} y={lowerWhisker.cy} width={3} height={height} fill='black' stroke='none' />
      {/* upper bar */}
      <Rectangle x={x} y={base - (median - lowerWhisker.value) * ratio} width={width} height={-((upperHinge - median) * ratio)} stroke='none' fill={'#e1e1e1'} fillOpacity={0.5} />
      {/* lower bar */}
      <Rectangle x={x} y={base - (median - lowerWhisker.value) * ratio} width={width} height={(median - lowerHinge) * ratio} stroke='none' fill={'#bdbdbd'} fillOpacity={0.5} />
    </>
  )
}

const calcHinge = (data) => {
  if (data.length === 0) {
    return { lowerHinge: 0, upperHinge: 0, median: 0, uniqueNamesCount: 0 }
  }
  const sortedData = data.sort((a, b) => a.value - b.value)

  const lowerHinge = calculateQuartile(sortedData, 0.25)
  const upperHinge = calculateQuartile(sortedData, 0.75)
  const median = calculateMedian(sortedData.map((i) => i.value))
  const uniqueNamesCount = new Set(data.map((d) => d.name)).size

  return { lowerHinge, upperHinge, median, uniqueNamesCount }
}

const calculateWhiskers = (data) => {
  const sortedData = data.sort((a, b) => a.value - b.value)
  const lowerWhisker = sortedData[0]
  const upperWhisker = sortedData[sortedData.length - 1]

  return {
    upperWhisker,
    lowerWhisker,
  }
}

const calculateQuartile = (data, percentile) => {
  const index = (data.length - 1) * percentile
  const lowerIndex = Math.floor(index)
  const upperIndex = Math.ceil(index)

  const lowerValue = data[lowerIndex].value
  const upperValue = data[upperIndex].value

  const interpolation = index - lowerIndex

  return lowerValue + (upperValue - lowerValue) * interpolation
}

const calculateMedian = (sortedValues) => {
  const middleIndex = Math.floor(sortedValues.length / 2)

  if (sortedValues.length % 2 === 0) {
    return (sortedValues[middleIndex - 1] + sortedValues[middleIndex]) / 2
  } else {
    return sortedValues[middleIndex]
  }
}

export default CandleStickView
