import { Button } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { restAPIRequest } from '../services/restApiRequest'
import { ExcelIcon } from '../ui-components'

interface ExportButtonProps {
  filename?: string
  type?: 'trxModel' | 'fin' | 's3'
  data?: any
  action?: () => void
}

const saveFile = async (blob, filename) => {
  const a = document.createElement('a')
  a.download = filename
  a.href = URL.createObjectURL(blob)
  a.addEventListener('click', (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
  })
  a.click()
}

async function requestExport(data: any, type: string, filename: string) {
  const res = await restAPIRequest<string>({
    body: data,
    path: `exports/${type}`,
    method: 'post',
    responseType: 'blob',
  })
  const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  saveFile(blob, filename)
}

const ExportButton: FunctionComponent<ExportButtonProps> = ({ filename, type, data, action }) => {
  const [saving, setSaving] = useState(false)
  return (
    <Button
      isLoading={saving}
      loadingText='Exporting...'
      variation='primary'
      size='small'
      gap='0.5rem'
      className='excel'
      onClick={async () => {
        if (saving) return
        setSaving(true)
        if (action) {
          try {
            return action()
          } catch (error) {
            return console.error('Error running action', error)
          } finally {
            setSaving(false)
          }
        }
        if (!filename || !type || !data) {
          alert('Filename, type and data are required')
          setSaving(false)
          return
        }
        await requestExport(data, type, filename)
          .catch((e) => {
            console.error('Error exporting', e)
            const enc = new TextDecoder('utf-8')

            let errorMsg = '{"error": ""}'
            if (e?.response?.data instanceof ArrayBuffer) {
              errorMsg = enc.decode(new Uint8Array(e.response.data))
            }
            const msg = JSON.parse(errorMsg)?.error
            alert(`Error generating export. Fix inputs ${msg} and try again`)
          })
          .finally(() => {
            setSaving(false)
          })
      }}
    >
      <ExcelIcon />
      Export to Excel
    </Button>
  )
}

export default ExportButton
